import {
	collection,
	query,
	orderBy,
	startAfter,
	limit,
	getDocs,
	limitToLast,
	getDoc,
	doc,
	where,
	addDoc,
	updateDoc,
	deleteDoc,
	increment,
	endBefore,
	setDoc,
	collectionGroup,
} from 'firebase/firestore';
import algoliasearch from 'algoliasearch';
import { getClientDB, getOrganisationIdFirebase } from 'api/AxiosManager';
import moment from 'moment';
import { masterDb } from '../firebaseConfig';

export const GetSocialPosts = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
				),
				orderBy('created_time', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);

			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
				),
				orderBy('created_time', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
				),
				orderBy('created_time', 'desc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs.map((document: any) => ({
			post_doc_id: document.id,
			...document.data(),
		}));
		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};
export const GetSocialPostsByAccounts = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	platform,
	account_name,
	page_name,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			let qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
				),
				orderBy('created_time', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			if (platform !== '') {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
					),
					where('platform', '==', platform),
					orderBy('created_time', 'desc'),
					startAfter(lastDocument),
					limit(rowsPerPage)
				);
				if (account_name !== '') {
					if(platform === 'Facebook') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
							),
							//		where('platform', '==', platform),
							where('account_name', '==', account_name),
							orderBy('created_time', 'desc'),
							startAfter(lastDocument),
							limit(rowsPerPage)
						);
					}else {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
							),
							//		where('platform', '==', platform),
							where('org_id', '==', account_name),
							orderBy('created_time', 'desc'),
							startAfter(lastDocument),
							limit(rowsPerPage)
						);
					}

					if (page_name !== '') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
							),
							where('page_id', '==', page_name),
							orderBy('created_time', 'desc'),
							startAfter(lastDocument),
							limit(rowsPerPage)
						);
					}
				}
			}
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			let qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
				),
				orderBy('created_time', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			if (platform !== '') {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
					),
					where('platform', '==', platform),
					orderBy('created_time', 'desc'),
					endBefore(firstDocument),
					limitToLast(rowsPerPage)
				);
				if (account_name !== '') {
					if(platform === 'Facebook') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
							),
							where('account_name', '==', account_name),
							orderBy('created_time', 'desc'),
							endBefore(firstDocument),
							limitToLast(rowsPerPage)
						);
					}else {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
							),
							where('org_id', '==', account_name),
							orderBy('created_time', 'desc'),
							endBefore(firstDocument),
							limitToLast(rowsPerPage)
						);
					}

					if (page_name !== '') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
							),
							where('page_id', '==', page_name),
							orderBy('created_time', 'desc'),
							endBefore(firstDocument),
							limitToLast(rowsPerPage)
						);
					}
				}
			}
			querySnapshots = await getDocs(qry);
		} else {
			let qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
				),
				orderBy('created_time', 'desc'),
				limit(rowsPerPage)
			);
			if (platform !== '') {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
					),
					where('platform', '==', platform),
					orderBy('created_time', 'desc'),
					limit(rowsPerPage)
				);
				if (account_name !== '') {
					qry = query(
						collection(
							getClientDB(),
							`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
						),
						where('account_name', '==', account_name),
						orderBy('created_time', 'desc'),
						limit(rowsPerPage)
					);
					if (platform === 'Linkedin') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
							),
							where('org_id', '==', account_name),
							orderBy('created_time', 'desc'),
							limit(rowsPerPage)
						);
					}
					if (page_name !== '') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
							),
							where('page_id', '==', page_name),
							orderBy('created_time', 'desc'),
							limit(rowsPerPage)
						);
					}
				}
			}

			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs?.map((document: any) => ({
			post_doc_id: document.id,
			...document.data(),
		}));
		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const GetSocialComments = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	platform,
	account_name,
	page_name,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			let qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
				),
				orderBy('created_time', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			if (platform !== '') {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
					),
					where('platform', '==', platform),
					orderBy('created_time', 'desc'),
					startAfter(lastDocument),
					limit(rowsPerPage)
				);
				if (account_name !== '') {
					if(platform === 'Facebook') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
							),
							//		where('platform', '==', platform),
							where('account_name', '==', account_name),
							orderBy('created_time', 'desc'),
							startAfter(lastDocument),
							limit(rowsPerPage)
						);
					}else {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
							),
							//		where('platform', '==', platform),
							where('org_id', '==', account_name),
							orderBy('created_time', 'desc'),
							startAfter(lastDocument),
							limit(rowsPerPage)
						);
					}

					if (page_name !== '') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
							),
							where('page_id', '==', page_name),
							orderBy('created_time', 'desc'),
							startAfter(lastDocument),
							limit(rowsPerPage)
						);
					}
				}
			}
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			let qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
				),
				orderBy('created_time', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			if (platform !== '') {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
					),
					where('platform', '==', platform),
					orderBy('created_time', 'desc'),
					endBefore(firstDocument),
					limitToLast(rowsPerPage)
				);
				if (account_name !== '') {
					if(platform === 'Facebook') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
							),
							where('account_name', '==', account_name),
							orderBy('created_time', 'desc'),
							endBefore(firstDocument),
							limitToLast(rowsPerPage)
						);
					}else {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
							),
							where('org_id', '==', account_name),
							orderBy('created_time', 'desc'),
							endBefore(firstDocument),
							limitToLast(rowsPerPage)
						);
					}

					if (page_name !== '') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
							),
							where('page_id', '==', page_name),
							orderBy('created_time', 'desc'),
							endBefore(firstDocument),
							limitToLast(rowsPerPage)
						);
					}
				}
			}
			querySnapshots = await getDocs(qry);
		} else {
			let qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
				),
				orderBy('created_time', 'desc'),
				limit(rowsPerPage)
			);
			if (platform !== '') {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
					),
					where('platform', '==', platform),
					orderBy('created_time', 'desc'),
					limit(rowsPerPage)
				);
				if (account_name !== '') {
					qry = query(
						collection(
							getClientDB(),
							`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
						),
						where('account_name', '==', account_name),
						orderBy('created_time', 'desc'),
						limit(rowsPerPage)
					);
					if (platform === 'Linkedin') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
							),
							where('org_id', '==', account_name),
							orderBy('created_time', 'desc'),
							limit(rowsPerPage)
						);
					}
					if (page_name !== '') {
						qry = query(
							collection(
								getClientDB(),
								`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
							),
							where('page_id', '==', page_name),
							orderBy('created_time', 'desc'),
							limit(rowsPerPage)
						);
					}
				}
			}

			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs?.map((document: any) => ({
			post_doc_id: document.id,
			...document.data(),
		}));
		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const GetSocialPostsCount = async () => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Social_Media/Feeds_Count/Count`
			)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const GetSocialPostsCountByAccountId = async (id) => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Social_Media/Feeds_Count/${id}`
			)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const GetSocialPostsCountByPageId = async (id) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Social_Media/Feeds_Count`
			),
			where('Page_Name', '==', id)
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetLinkedInPages = async () => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Social_Media/Unique_Li_Orgs/List`
			)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const GetComments = async (id: string) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/Comments_V2/${id}/${id}`
			),
			orderBy("created_date_time", "desc")
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) => ({
			comment_doc_id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetAllComments = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/All_Comments`
			)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) =>
			document.data()
		);
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetReplies = async (id: string) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/Replies_V2/${id}/${id}`
			)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) => ({
			reply_doc_id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetPostData = async (id: string) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`
			),
			where('id', '==', id)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) => ({
			post_doc_id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetSocialPostsByAlgolia = (
	hitsPerPage: number,
	page: number,
	facetFilters
) =>
	new Promise((resolve, reject) => {
		const client = algoliasearch(
			'Q5DIQNT62L',
			'ef408a387f62fdbe4c38a2f8d07bb273'
		);
		const index = client.initIndex('feed');
		index
			.search('', {
				hitsPerPage,
				page,
				facetFilters,
			})
			.then((result) => {
				resolve(result);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const GetUsers = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/Users/Users`),
			where('Display', '!=', false)
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};
export const GetGroups = async () => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/Groups/Groups`)
		);
		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetSentTasks = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/Task_Management`
			),
			where('Display', '!=', false)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) =>
			document.data()
		);
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetCampaignNotifications = async (userDocId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`
			),
			where('Campaign_Approver.User_Doc_Id', '==', userDocId),
			where('Approval_Status', '==', 'Pending'),
			where('Is_Read', '==', false)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) => ({
			type: 'campaignInbox',
			campaignId: document.id,
			...document.data(),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetNotifications = async (id) => {
	const collectionRef: any = getClientDB().collection(
		`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received`
	);

	try {
		const notes: any = [];
		const documents: any = await collectionRef.get();
		documents.forEach(async (item) => {
			const subCollectionDocs = await collectionRef
				.doc(item.id)
				.collection('Notes')
				.where('Is_Read', '==', false)
				.get();
			subCollectionDocs.forEach((obj) =>
				notes.push({ id: item.id, Note_Id: obj.id, ...obj.data() })
			);
		});
		const campaignData = await GetCampaignNotifications(id);

		const result: any = notes?.concat(campaignData);

		return result ?? [];
	} catch (error) {
		return error;
	}
};

export const ClearNotifications = async (id) => {
	const taskDocRef = getClientDB().collection(
		`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received`
	);
	const campaignDocRef = getClientDB()
		.collection(`/${getOrganisationIdFirebase()}/Campaigns/Campaigns`)
		.where('Campaign_Approver.User_Doc_Id', '==', id)
		.where('Approval_Status', '==', 'Pending')
		.where('Is_Read', '==', false);

	try {
		taskDocRef.get().then((querySnapshot) => {
			querySnapshot.forEach((docs) => {
				docs.ref.update({ Is_Read: true });
			});
		});
		campaignDocRef.get().then((querySnapshot) => {
			querySnapshot.forEach((docs) => {
				docs.ref.update({ Is_Read: true });
			});
		});

		return {};
	} catch (err) {
		return null;
	}
};

export const GetSentTaskList = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	id,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Sent`
				),
				orderBy('Created_Date_Time', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Sent`
				),
				orderBy('Created_Date_Time', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Sent`
				),
				orderBy('Created_Date_Time', 'desc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const GetReceivedTaskList = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	id,
	taskStatus,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received`
				),
				where('Task_Status', '==', taskStatus),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received`
				),
				where('Task_Status', '==', taskStatus),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received`
				),
				where('Task_Status', '==', taskStatus),

				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return {
			data: resultArray.filter((obj) => obj?.Display !== false),
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const AssignTaskToUser = async (payload, id, taskDocId) => {
	try {
		const result = await setDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received`,
				taskDocId
			),
			{
				...payload,
				Feeds_Path: `/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2/${
					payload.Post_Id
				}`,
				Comments_Path: `/${getOrganisationIdFirebase()}/Social_Media/Comments_V2`,
				Replies_Path: `/${getOrganisationIdFirebase()}/Social_Media/Replies_V2`,
				Created_Date_Time: moment().toString(),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const UpdateReceivedTask = async (payload, userDocId, taskDocId) => {
	try {
		const result = await updateDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Received/${taskDocId}`
			),

			payload
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const UpdateSentTask = async (payload, userDocId, taskDocId) => {
	try {
		const result = await updateDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Sent`,
				taskDocId
			),
			payload
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const AssignTaskByUser = async (payload, id) => {
	try {
		const result: any = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Sent`
			),
			{
				...payload,
				Feeds_Path: `/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2/${
					payload.Post_Id
				}`,
				Comments_Path: `/${getOrganisationIdFirebase()}/Social_Media/Comments_V2`,
				Replies_Path: `/${getOrganisationIdFirebase()}/Social_Media/Replies_V2`,
				Created_Date_Time: moment().toString(),
			}
		);
		return result?.id;
	} catch (error) {
		return error;
	}
};
export const UpdateSentCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Inbox_Sent_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const GetSentCount = async (id) => {
	try {
		const result = await getDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`)
		);
		return result?.data().Inbox_Sent_Count;
	} catch (error) {
		return error;
	}
};
export const GetReceivedCount = async (id) => {
	try {
		const result: any = await getDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`)
		);
		return result?.data().Inbox_Received_Count;
	} catch (error) {
		return error;
	}
};
export const UpdateReceivedCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Inbox_Received_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const UpdateSocialUnReadCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Inbox_Social_Un_Read_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const GetCampaignsCreatedCount = async (id) => {
	try {
		const result: any = await getDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`)
		);
		return result?.data().Campaigns_Created_Count;
	} catch (error) {
		return error;
	}
};
export const UpdateCampaignsCreatedCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Campaigns_Created_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const GetCampaignsOwnedCount = async (id) => {
	try {
		const result: any = await getDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`)
		);
		return result?.data().Campaigns_Owned_Count;
	} catch (error) {
		return error;
	}
};
export const UpdateCampaignsOwnedCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Campaigns_Owned_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const GetCampaignsApprovalPendingCount = async (id) => {
	try {
		const result: any = await getDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`)
		);
		return result?.data().Campaigns_Approval_Pending_Count;
	} catch (error) {
		return error;
	}
};
export const GetCampaignsUnReadCount = async (id) => {
	try {
		const result: any = await getDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`)
		);
		return result?.data().Campaigns_Un_Read_Count;
	} catch (error) {
		return error;
	}
};
export const UpdateCampaignsApprovalPendingCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Campaigns_Approval_Pending_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const GetCampaignsApprovedCount = async (id) => {
	try {
		const result: any = await getDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`)
		);
		return result?.data().Campaigns_Approved_Count;
	} catch (error) {
		return error;
	}
};
export const UpdateCampaignsApprovedCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Campaigns_Approved_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const UpdateCampaignsUnReadCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Campaigns_Un_Read_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const GetCampaignsRejectedCount = async (id) => {
	try {
		const result: any = await getDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`)
		);
		return result?.data().Campaigns_Rejected_Count;
	} catch (error) {
		return error;
	}
};
export const UpdateCampaignsRejectedCount = async (id, value) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/Users/Users/${id}`),
			{
				Campaigns_Rejected_Count: increment(value),
			}
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const createSentNotes = async (payload, id, docId) => {
	try {
		const result: any = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Sent/${docId}/Notes`
			),
			{
				...payload,
				Created_Date_Time: moment().toString(),
			}
		);
		return result?.id;
	} catch (error) {
		return error;
	}
};

export const createReceivedNotes = async (payload, id, docId) => {
	try {
		const result: any = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received/${docId}/Notes`
			),
			{
				...payload,
				Created_Date_Time: moment().toString(),
			}
		);
		return result?.id;
	} catch (error) {
		return error;
	}
};

export const GetSentNotesList = async (id, docId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Sent/${docId}/Notes`
			)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) => ({
			note_id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetReceivedNotesList = async (id, docId) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received/${docId}/Notes`
			)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) => ({
			note_id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetReceivedTaskById = async (userDocId, taskDocId) => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Received/${taskDocId}`
			)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};
export const GetSentTaskById = async (userDocId, taskDocId) => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Sent`,
				taskDocId
			)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const GetTaskById = async (id) => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Users/Users/${id}/Inbox_Social_Received`,
				id
			)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const GetTaskStatusById = async (id: string) => {
	try {
		const qry = query(
			collectionGroup(getClientDB(), `Inbox_Social_Received`),
			where('id', '==', id)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) => ({
			post_doc_id: document.id,
			...document.data(),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const UpdateFeedData = async (id, data) => {
	try {
		const result = await updateDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/Feeds_V2`,
				id
			),
			data
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const UpdateCommentData = async (id, docId, data) => {
	try {
		const result = await updateDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/Comments_V2/${id}/${id}`,
				docId
			),
			data
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const GetTaskList = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Task_Management`
				),
				orderBy('Created_Date_Time', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Task_Management`
				),
				orderBy('Created_Date_Time', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Task_Management`
				),
				orderBy('Created_Date_Time', 'desc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const DeleteTask = async (task: any) => {
	try {
		const result = await deleteDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/Task_Management`,
				task.id
			)
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const DeleteSentTask = async (userDocId, task: any) => {
	try {
		const result = await deleteDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Sent`,
				task.id
			)
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const DeleteReceivedTask = async (userDocId, id: any) => {
	try {
		const result = await deleteDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Received`,
				id
			)
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const UpdateReceivedNote = async (payload, userDocId, taskDocId, id) => {
	try {
		const result = await updateDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Received/${taskDocId}/Notes/${id}`
			),

			payload
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const UpdateSentNote = async (payload, userDocId, taskDocId, id) => {
	try {
		const result = await updateDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Sent/${taskDocId}/Notes`,
				id
			),
			payload
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const DeleteSentNote = async (userDocId, tasKDocId, task: any) => {
	try {
		const result = await deleteDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Sent/${tasKDocId}/Notes`,
				task.id
			)
		);
		return result;
	} catch (error) {
		return error;
	}
};
export const DeleteReceivedNote = async (userDocId, tasKDocId, id: any) => {
	try {
		const result = await deleteDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Users/Users/${userDocId}/Inbox_Social_Received/${tasKDocId}/Notes`,
				id
			)
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const GetTasksCount = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts')
		);
		return docResult.data().Social_Tasks;
	} catch (error) {
		return error;
	}
};

export const UpdateTasksCount = async (value: any) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts'),
			{
				Social_Tasks: increment(value),
			}
		);
		return result;
	} catch (err) {
		return null;
	}
};

export const GetSocialLogs = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Logs`
				),
				//	where('Display', '!=', false),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Logs`
				),
				//	where('Display', '!=', false),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Social_Media/Logs`
				),
				//	where('Display', '!=', false),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return {
			data: resultArray.filter((item) => item.Display !== false), // Display property is not coming in evry document , when i use it in query, not returning any data
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const GetSocialLogsCount = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts')
		);
		return docResult.data().Social_Logs;
	} catch (error) {
		return error;
	}
};

export const GetSocialExtras = async () => {
	try {
		const qry = query(collection(masterDb, `/Social_Media_Drop_Down_List`));
		const result = await getDocs(qry);

		const resultArray = result.docs.map((document: any) => ({
			...document.data(),
		}));
		return resultArray[0];
	} catch (error) {
		return error;
	}
};

export const UpdateSocialLogsCount = async (value: any) => {
	try {
		const result = await updateDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts'),
			{
				Social_Logs: increment(value),
			}
		);
		return result;
	} catch (err) {
		return null;
	}
};

export const DeleteSocialLog = async (log: any) => {
	try {
		const result = await deleteDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Social_Media/Logs`,
				log.id
			)
		);
		return result;
	} catch (error) {
		return error;
	}
};
