import { getClientDB, getOrganisationIdFirebase } from 'api/AxiosManager';
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	addDoc,
	where,
} from 'firebase/firestore';
import moment from 'moment';

export const SaveLocation = async (
	payload: any,
	locationId: any,
	organizationId: any
) => {
	try {
		let result;
		const locationSnapshots = await getDocs(
			query(
				collection(getClientDB(), `/${organizationId}/Locations/Locations`),
				where('Location_Name', '==', payload.Location_Name)
			)
		);

		if (
			locationSnapshots.size === 0 ||
			locationSnapshots.docs[0].id === locationId
		) {
			await setDoc(
				doc(getClientDB(), `/${organizationId}/Locations/Locations`, locationId),
				{
					...payload,
					Created_At: moment().toString(),
				}
			);

			result = {
				success: true,
			};
		} else {
			result = {
				success: false,
				message: 'Location already exist',
			};
		}

		return result;
	} catch (error) {
		return error;
	}
};

export const GetLocationInfoById = async (locationId, organizationId: any) => {
	try {
		const result = await getDoc(
			doc(getClientDB(), `/${organizationId}/Locations/Locations`, locationId)
		);
		return result.data();
	} catch (error) {
		return error;
	}
};

export const SaveRegion = async (
	payload: any,
	regionId: any,
	organizationId: any
) => {
	try {
		const result = await setDoc(
			doc(getClientDB(), `/${organizationId}/Lookups/Regions`, regionId),
			payload
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const GetRegionById = async (regionId: any, organizationId: any) => {
	try {
		const result = await getDoc(
			doc(getClientDB(), `/${organizationId}/Lookups/Regions`, regionId)
		);
		return result.data();
	} catch (error) {
		return error;
	}
};

// delete location by id
export const DeleteLocationById = async (
	locationId: any,
	organizationId: any
) => {
	try {
		const result = await deleteDoc(
			doc(getClientDB(), `/${organizationId}/Locations/Locations`, locationId)
		);
		return result;
	} catch (error) {
		return error;
	}
};

// delete region by id
export const DeleteRegionById = async (regionId: any, organizationId: any) => {
	try {
		const result = await deleteDoc(
			doc(getClientDB(), `/${organizationId}/Lookups/Regions`, regionId)
		);
		return result;
	} catch (error) {
		return error;
	}
};

export const GetAllLocations = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Locations/Locations`
			)
		);
		const resultSnapshots = await getDocs(qry);
		const resultArray = resultSnapshots.docs.map((document) => ({
			...document.data(),
			id: document.id,
		}));

		return {
			success: true,
			data: resultArray,
		};
	} catch (error) {
		return error;
	}
};
export const GetAllLocationscopy = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/Locations/Locations`
			)
		);
		const resultSnapshots = await getDocs(qry);
		const resultArray: any = resultSnapshots.docs.map((document) => ({
			id: document.id,
			...document.data(),
		}));

		return resultArray.filter((item) => item?.Display !== false);
	} catch (error) {
		return error;
	}
};
export const GetLocationById = async (locationId) => {
	try {
		const result = await getDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/Locations/Locations`,
				locationId
			)
		);
		return result.data();
	} catch (error) {
		return error;
	}
};
export const UpdateLocations = async (
	payload: any,
	locationId: any,
	organizationId: any
) => {
	try {
		let result;
		const locationSnapshots = await getDocs(
			query(
				collection(getClientDB(), `/${organizationId}/Locations/Locations`),
				where('Location_Name', '==', payload.Location_Name)
			)
		);

		if (
			locationSnapshots.size === 0 ||
			locationSnapshots.docs[0].id === locationId
		) {
			await setDoc(
				doc(getClientDB(), `/${organizationId}/Locations/Locations`, locationId),
				{
					...payload,
					Updated_At: moment().toString(),
				}
			);

			result = {
				success: true,
			};
		} else {
			result = {
				success: false,
				message: 'Location already exist',
			};
		}

		return result;
	} catch (error) {
		return error;
	}
};
export const SaveLocations = async (payload: any, organizationId: any) => {
	try {
		let result;
		const locationSnapshots = await getDocs(
			query(
				collection(getClientDB(), `/${organizationId}/Locations/Locations`),
				where('Location_Name', '==', payload.Location_Name)
			)
		);

		if (locationSnapshots.size === 0) {
			const resultId: any = await addDoc(
				collection(getClientDB(), `/${organizationId}/Locations/Locations`),
				{
					...payload,
					Created_At: moment().toString(),
				}
			);
			result = {
				success: true,
				id: resultId.id,
			};
		} else {
			result = {
				success: false,
				message: 'Location already exist',
			};
		}

		return result;
	} catch (error) {
		return error;
	}
};
