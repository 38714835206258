import Toast, { ToastTypes } from 'components/ToastNotify';
import { AddPosCoupon, UpdatePosCoupon } from 'firebaseApis/pos-coupons';
import { useFormik } from 'formik';
import { getDateFormat } from 'helpers/Formats';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import MUiDatePicker from 'storybook-mui/build/components/MUiDatePicker';
import NumberField from 'storybook-mui/build/components/NumberField';
import RadioField from 'storybook-mui/build/components/Radio';
import SelectField from 'storybook-mui/build/components/Select';
import { SwitchField } from 'storybook-mui/build/components/Switch/Switch';
import InputTextField from 'storybook-mui/build/components/TextField';
import { CouponValidationSchema } from '../Common/validations';

function AddCoupon({
	showAddEditModal,
	setShowAddEditModal,
	getData,
	type = 'add',
	couponData = {},
}: IAddTeamMemberProps) {
	const dispatch = useDispatch();

	const couponForm = useFormik({
		initialValues: {
			Coupon_Type: 'SubTotal',
			Coupon_Name: '',
			Coupon_Code: '',
			Start_Date: '',
			End_Date: '',
			Discount: '',
			Discount_Type: 'Percentage',
			Discount_Amount: 0,
			Discount_Percentage: 0,
			Minimum_Order_Amount: 0,
			Duration: 'Forever',
			Status: 'Active',
			Maximum_Redemptions: 1000,
			Maximum_Redemptions_For_Customer: 1000,
			Is_Active: true,
			Redemptions_Done: 0,
		},
		validationSchema: CouponValidationSchema,
		onSubmit: async (values) => {
			try {
				dispatch(showLoading());
				let response: any;
				if (type === 'add') {
					response = await AddPosCoupon(values);
				} else {
					response = await UpdatePosCoupon({
						...values,
						id: couponData.id,
					});
				}
				if (response.success) {
					Toast({ title: response.message, type: ToastTypes.SUCCESS });
					getData();
				} else {
					Toast({ title: response.message, type: ToastTypes.ERROR });
				}
				dispatch(hideLoading());
				setShowAddEditModal(false);
			} catch (error) {
				dispatch(hideLoading());
				Toast({ title: error.message, type: ToastTypes.ERROR });
			}
		},
	});

	useEffect(() => {
		if (type === 'edit') {
			couponForm.setValues(couponData);
		}
	}, []);

	return (
		<div>
			<Modal
				open={showAddEditModal}
				setOpen={setShowAddEditModal}
				title='Add Coupon'
				content={
					<form onSubmit={couponForm.handleSubmit}>
						<div className='grid grid-cols-3 gap-5 mt-5'>
							<SelectField
								name='Coupon Type'
								fieldId='Coupon_Type'
								onChange={couponForm.handleChange}
								options={[
									{
										label: 'SubTotal',
										value: 'SubTotal',
									},
								]}
								displayField='label'
								valueField='value'
								placeholder='Select Coupon Type'
								value={couponForm.values.Coupon_Type}
								errorMessage={couponForm.errors.Coupon_Type}
								valid={couponForm.errors.Coupon_Type === undefined}
							/>
							<InputTextField
								name='Coupon_Name'
								fieldId='Coupon_Name'
								onChange={couponForm.handleChange}
								value={couponForm.values.Coupon_Name}
								labelName='Coupon Name'
								placeholder='Enter Coupon Name'
								errorMessage={couponForm.errors.Coupon_Name}
								valid={!couponForm.errors.Coupon_Name}
							/>
							<InputTextField
								name='Coupon_Code'
								fieldId='Coupon_Code'
								onChange={couponForm.handleChange}
								value={couponForm.values.Coupon_Code}
								labelName='Coupon Code'
								placeholder='Enter Coupon Code'
								errorMessage={couponForm.errors.Coupon_Code}
								valid={!couponForm.errors.Coupon_Code}
							/>

							<div className='col-span-3 flex flex-col'>
								<RadioField
									name='Discount_Type'
									fieldId='Discount_Type'
									onChange={(e) =>
										couponForm.setFieldValue('Discount_Type', e.target.value)
									}
									options={[
										{ label: 'Percentage', value: 'Percentage' },
										{ label: 'Amount', value: 'Amount' },
									]}
									valid={!couponForm.errors.Discount_Type}
									labelName='Discount Type'
									checked={couponForm.values.Discount_Type}
									align='row'
								/>
								<div className='w-96'>
									{couponForm.values.Discount_Type === 'Amount' ? (
										<NumberField
											name='Discount_Amount'
											fieldId='Discount_Amount'
											onChange={couponForm.handleChange}
											value={couponForm.values.Discount_Amount}
											labelName='Discount Amount'
											placeholder='Enter Discount Amount'
											errorMessage={couponForm.errors.Discount_Amount}
											valid={!couponForm.errors.Discount_Amount}
											min={0}
											max={100000}
										/>
									) : (
										<NumberField
											name='Discount_Percentage'
											fieldId='Discount_Percentage'
											onChange={couponForm.handleChange}
											value={couponForm.values.Discount_Percentage}
											labelName='Discount Percentage'
											placeholder='Discount Percentage'
											errorMessage={couponForm.errors.Discount_Percentage}
											valid={!couponForm.errors.Discount_Percentage}
											min={0}
											max={100}
										/>
									)}
								</div>
							</div>

							<div className='col-span-3 flex flex-col'>
								<RadioField
									name='Duration'
									fieldId='Duration'
									onChange={(e) => couponForm.setFieldValue('Duration', e.target.value)}
									options={[
										{
											label: 'Forever',
											value: 'Forever',
										},
										{
											label: 'Limited',
											value: 'Limited',
										},
									]}
									valid={!couponForm.errors.Duration}
									labelName='Coupon Duration'
									checked={couponForm.values.Duration}
									align='row'
								/>

								{couponForm.values.Duration === 'Limited' && (
									<div className='col-span-2 flex gap-5'>
										<MUiDatePicker
											name='Start_Date'
											fieldId='Start_Date'
											onChange={(e) =>
												couponForm.setFieldValue('Start_Date', getDateFormat(e))
											}
											value={couponForm.values.Start_Date}
											labelName='Start Date'
											selected={couponForm.values.Start_Date}
											errorMessage={couponForm.errors.Start_Date}
											valid={!couponForm.errors.Start_Date}
										/>
										<MUiDatePicker
											name='End_Date'
											fieldId='End_Date'
											onChange={(e) =>
												couponForm.setFieldValue('End_Date', getDateFormat(e))
											}
											value={couponForm.values.End_Date}
											labelName='End Date'
											selected={couponForm.values.End_Date}
											errorMessage={couponForm.errors.End_Date}
											valid={!couponForm.errors.End_Date}
										/>
									</div>
								)}
							</div>

							<div className='col-span-3 flex gap-5'>
								<InputTextField
									name='Maximum_Redemptions'
									fieldId='Maximum_Redemptions'
									onChange={couponForm.handleChange}
									value={couponForm.values.Maximum_Redemptions}
									labelName='Maximum Redemptions'
									placeholder='Enter Maximum Redemptions'
									errorMessage={couponForm.errors.Maximum_Redemptions}
									valid={!couponForm.errors.Maximum_Redemptions}
								/>
								<InputTextField
									name='Maximum_Redemptions_For_Customer'
									fieldId='Maximum_Redemptions_For_Customer'
									onChange={couponForm.handleChange}
									value={couponForm.values.Maximum_Redemptions_For_Customer}
									labelName='Maximum Redemptions Per Customer'
									placeholder='Enter Maximum Redemptions Per Customer'
									errorMessage={couponForm.errors.Maximum_Redemptions_For_Customer}
									valid={!couponForm.errors.Maximum_Redemptions_For_Customer}
								/>
								<div className='w-96'>
									<SwitchField
										name='Is_Active'
										fieldId='Is_Active'
										onChange={couponForm.handleChange}
										checked={couponForm.values.Is_Active}
										labelName='Is Active'
									/>
								</div>
							</div>

							<div className='col-span-3 flex justify-end gap-5'>
								<Button
									title='Cancel'
									onClick={() => setShowAddEditModal(false)}
									variant='outlined'
									color='primary'
								/>
								<Button
									title='Save'
									type='submit'
									onClick={() => {}}
									variant='contained'
									color='primary'
								/>
							</div>
						</div>
					</form>
				}
				modalId='addEditCoupon'
				size='md'
			/>
		</div>
	);
}

export default AddCoupon;

export interface IAddTeamMemberProps {
	showAddEditModal: boolean;
	setShowAddEditModal: (value: boolean) => void;
	getData: () => void;
	type?: 'add' | 'edit';
	couponData?: any;
}
