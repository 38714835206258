import React, {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress, {
	LinearProgressProps,
} from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(
	props: LinearProgressProps & { value: number; textColor?: string }
) {
	const { value, textColor } = props;
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant='determinate' {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<p className={`text-${textColor} font-bold text-base`}>{`${Math.round(
					value
				)}%`}</p>
			</Box>
		</Box>
	);
}

function LinearProgressBar({
	delay = 1000,
	color = 'info',
	message = '',
	showBackDrop = true,
	textColor = 'black',
	incrementBy = 10,
	currentProgress = 0,
}: ITextInfo) {
	const [progress, setProgress] = useState<number>(0);
	const [intervalValue, setIntervalValue] = useState<any>(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setProgress(counter => counter + incrementBy);
		}, Math.ceil(delay / 100));
		setIntervalValue(interval);
		return () => {
			clearInterval(interval);
		};
	}, []);
	useEffect(() => {
		if (progress === 100 || currentProgress === 100) {
			setProgress(100);
			clearInterval(intervalValue);
		}
	}, [progress, currentProgress])
	if (showBackDrop)
		return (
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open
			>
				<div className='w-full md:w-2/3 mx-auto'>
					<p className='text-white font-semibold text-lg italic'>{message}</p>
					<LinearProgressWithLabel value={progress} color={color} />
				</div>
			</Backdrop>
		);

	return (
		<div className='w-full md:w-2/3 mx-auto'>
			<p className={`text-${textColor} font-semibold text-lg italic`}>{message}</p>
			<LinearProgressWithLabel
				value={progress}
				color={color}
				textColor={textColor}
			/>
		</div>
	);
}

export interface ITextInfo {
	delay?: number;
	incrementBy?: number;
	currentProgress?: number;
	message?: string;
	showBackDrop?: boolean;
	onProgressComplete?: any;
	color?:
		| 'primary'
		| 'secondary'
		| 'warning'
		| 'success'
		| 'error'
		| 'info'
		| 'inherit';
	textColor?: string;
}

export default LinearProgressBar;
