import moment from 'moment';
import {
	Frequency,
	Interval,
} from 'reducers/NewCampaign/NewCampaignActionTypes';
import {
	getOrgnaizationId,
	create,
	getUser,
	createCloud,
	getUserId,
	createCloudRun,
	siteConfigConstants,
	axiosInstance,
} from './AxiosManager';

export const GetCampaignStatus = (start: string, end: string) =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const payload = {
			organization_id: organizationId,
			start_date: start !== '' ? start : moment().format('YYYY-MM-DD'),
			end_date: end !== '' ? end : moment().format('YYYY-MM-DD'),
		};
		const instance = create();
		instance
			.post(`/clientCampaign/getDashboardCampaignStatus`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const GetCampaigns = () =>
	new Promise((resolve, reject) => {
		const { FormatDate } = getUser().OtherConfig;
		const organizationId: string = getOrgnaizationId();
		const instance = create();
		instance
			.get(`/clientCampaign/getCampaignData?organization_id=${organizationId}`)
			.then((result) => {
				const dataSourse = result.data.map((obj: any) => ({
					...obj,
					CampaignCreateTime: moment(obj.CampaignCreateTime).format(FormatDate),
				}));
				resolve(dataSourse);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const GetCampaignsByFilter = (
	startDate: string,
	endDate: string,
	campaignType: string,
	campaignStatus: string
) =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const { FormatDate } = getUser().OtherConfig;
		const payload = {
			organization_id: organizationId,
			start_date: startDate,
			end_date: endDate,
			type: campaignType,
			status: campaignStatus,
		};
		const instance = create();
		instance
			.post(`/clientCampaign/getCampaignDataByFilters`, payload)
			.then((result) => {
				const dataSourse = result.data.map((obj: any) => ({
					...obj,
					CampaignCreateTime: moment(obj.CampaignCreateTime.value).format(
						FormatDate
					),
					LastModifiedDateTime: moment(obj.LastModifiedDateTime).format(FormatDate),
				}));
				resolve(dataSourse);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const ActiveInActiveCampaign = (
	campaignId: string,
	campaignStatus: number
) =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const payload = {
			organization_id: organizationId,
			campaignid: [campaignId],
			status: campaignStatus,
		};
		const instance = create();
		instance
			.post(`/clientCampaign/activeInActiveCampaign`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
export const SaveEmailCampaign = (data) =>
	new Promise((resolve, reject) => {
		const instance = createCloud();
		const headers = {
			organization_id: 't3c956d2a791cd33e1091218546e1f74a',
			created_by: getUserId(),
			'x-proj-key':
				'U2FsdGVkX18LKhjNb2s+/FrE11UVssvYIsH00MRj0ygnjqWou9hsHCAbq9nB3W8+SAXikgd6L1/ZX0GGr1b/nWhOeelzJNMM1vmiHq2KKG5Sk+aSqG4oAMmz37EHWsvWw4LVPWWggGonF99nS1mUmpigisJJe3D1AGYlQMKoFlx1zjfw9EN+pmNI3e40rfxt',
		};
		instance
			.post(`/v12_email-campaign/saveEmailCampaign`, data, { headers })
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const SaveNewCampaign = (
	campaignname: string,
	description: string,
	type: string,
	creativeid: number,
	interval: Interval,
	frequency: Frequency,
	timeoftheday: string,
	day: number,
	timezone: string,
	startdatetime: Date,
	enddatetime: Date,
	customerlistid: number,
	notify1_email: string,
	notify1_mobile: string,
	notify2_email: string,
	notify2_mobile: string,
	notify3_email: string,
	notify3_mobile: string,
	isEvent: boolean,

	scheduleeventcriteriaid: number,
	criterianame: string,
	dbcolumnname: string,
	valuetype: string,
	sqltoexecute: string,
	conditiontype: string,
	firstvalue: string,
	// secondvalue: string,
	// active: number,
	campaign_subject: string
) =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const { userId } = getUser();

		const payload: NewCampaign = {
			campaignname,
			description,
			type,
			creativeid,
			timeoftheday,
			interval,
			frequency: interval === Interval.Once ? 'runnow' : frequency,
			day: frequency === 'Daily' ? 0 : day,
			timezone,
			startdatetime,
			enddatetime,
			customerlistid,
			notify1_email,
			notify1_mobile,
			notify2_email,
			notify2_mobile,
			notify3_email,
			notify3_mobile,
			createdby: parseInt(userId, 10),
			createddatetime: new Date(),
			lastmodifiedby: parseInt(userId, 10),
			lastmodifieddatetime: new Date(),
			organization_id: organizationId,
			isEvent,
			scheduleeventcriteriaid,

			criterianame,

			dbcolumnname,
			valuetype,
			sqltoexecute,
			conditiontype,

			firstvalue,
			secondvalue: '',
			active: 1,
			campaign_subject,
		};
		const instance = create();
		instance
			.post(`/clientCampaign/saveCampaign`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export interface NewCampaign {
	campaignname: string;
	description: string;
	type: string;
	creativeid: number;
	timeoftheday: string;
	interval: Interval;
	frequency: string;
	day: number;
	timezone: string;
	startdatetime: Date;
	enddatetime: Date;
	customerlistid: number;
	notify1_email: string;
	notify1_mobile: string;
	notify2_email: string;
	notify2_mobile: string;
	notify3_email: string;
	notify3_mobile: string;
	createdby: number;
	createddatetime: Date;
	lastmodifiedby: number;
	lastmodifieddatetime: Date;
	organization_id: string;
	isEvent: boolean;
	scheduleeventcriteriaid: number;
	conditiontype: string;
	firstvalue: string;
	secondvalue: string;
	active: number;
	campaign_subject: string;
	criterianame: string;
	dbcolumnname: string;
	valuetype: string;
	sqltoexecute: string;
}

export const getEventCriteria = () =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const instance = create();
		instance
			.get(`/clientEvent/getEventCriteria?organization_id=${organizationId}`)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const InsertSingleCampaign = async (data) => {
	const instance = create();
	try {
		const result = await instance.post(
			`/importexportcampaigns/insertSingleCampaign?organization_id=${data.orgId}&campaign_id=${data.campaignId}`
		);
		return result;
	} catch (e) {
		return e;
	}
};

export const UpdateCampaignBigData = async (data) => {
	const instance = create();
	try {
		const result = await instance.post(
			`/importexportcampaigns/updateCampaign?organization_id=${data.orgId}&new_campaign_id=${data.campaignId}&old_campaign_id=${data.oldCampaignId}`
		);
		return result;
	} catch (e) {
		return e;
	}
};

export const DeleteCampaignBigData = async (data) => {
	const instance = create();
	try {
		const result = await instance.delete(
			`/importexportcampaigns/deleteCampaign?organization_id=${data.orgId}&campaign_id=${data.campaignId}`
		);
		return result;
	} catch (e) {
		return e;
	}
};

let cancelToken;

export const SearchCampaignByName = async (data) => {
	const instance = create();
	if (typeof cancelToken !== typeof undefined) {
		cancelToken.cancel('Operation canceled due to new request.');
	}
	cancelToken = axiosInstance().CancelToken.source();
	try {
		const result: any = await instance.post(
			`/dynamicdataset/getDynamicDataSetRelation?called_from=UI`,
			data,
			{ cancelToken: cancelToken.token }
		);
		return result?.data?.map((res) => ({ ...res.document, id: res.document_id }));
	} catch (e) {
		return null;
	}
};
export const GetSocialMediaAccounts = (): any =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const instance = create();
		const { userId } = getUser();
		instance
			.get(
				`/socialmedia/getSocialMediaClientAccounts?organization_id=${organizationId}&crmUserId=${userId}`
			)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const GetSocialLogs = () =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const instance = create();
		const { userId } = getUser();
		instance
			.get(
				`/socialmedia/getSocialCampaignLogs?OrganizationID=${organizationId}&crmUserId=${userId}`
			)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const SaveSocialCampaign = (
	SocialPostCreativeID: number,
	SocialInfo: any,
	CampaignRunType: string,
	CampaignName: string,
	CampaignDescription: string,
	SelectedTimeZone: string,
	RunAfterSeconds: number,
	CampaignScheduledOn: string
) =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const { userId } = getUser();

		const payload = {
			OrganizationID: organizationId,
			SocialPostCreativeID,
			SocialInfo,
			CreatedBy: userId,
			CampaignRunType,
			CampaignName,
			CampaignDescription,
			SelectedTimeZone,
			RunAfterSeconds,
			CampaignScheduledOn,
		};
		const instance = create();
		instance
			.post(`/socialmedia/saveCampaign`, payload)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
export const runSocialCampaign = (campaignID: number) =>
	new Promise((resolve, reject) => {
		const organizationId: string = getOrgnaizationId();
		const instance = create();
		instance
			.post(
				`/socialmedia/postToSocialMedia?campaignId=${campaignID}&organization_id=${organizationId}`
			)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const processEmailCampaign = (campaignID: number) =>
	new Promise((resolve, reject) => {
		const instance = createCloudRun(siteConfigConstants().REACT_APP_EMAIL_URL);
		instance
			.post(`/processEmailCampaign?Campaign_ID=${campaignID}`)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const processVoiceCampaign = (campaignID: number) =>
	new Promise((resolve, reject) => {
		const instance = createCloudRun(siteConfigConstants().REACT_APP_VOICE_URL);
		instance
			.post(`/processVoiceCampaign?Campaign_ID=${campaignID}`)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const processSocialCampaign = (campaignID: number) =>
	new Promise((resolve, reject) => {
		const instance = createCloudRun(siteConfigConstants().REACT_APP_SOCIAL_URL);
		instance
			.post(`/processSocialMediaCampaign?Campaign_ID=${campaignID}`)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const processSmsCampaign = (campaignID: number) =>
	new Promise((resolve, reject) => {
		const instance = createCloudRun(siteConfigConstants().REACT_APP_SMS_URL);
		instance
			.post(`/processSmsCampaign?Campaign_ID=${campaignID}`)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

export const updateScheduleRecurCampaign = async (payload) => {
	try {
		const instance = createCloudRun(siteConfigConstants().REACT_APP_EMAIL_URL);
		const result = await instance.post(`/deleteCampaign`, payload);
		return result;
	} catch (e) {
		return null;
	}
};
