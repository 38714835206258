import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import CreateOrg from 'views/NewSignUp/CreateOrg';
import ChooseOrg from 'views/NewSignUp/ChooseOrg';
import PaymentTerminals from 'views/POS/PaymentTerminals';
import RegisterForm from 'views/NewSignUp/RegisterForm';
import WelcomeScreen from 'views/NewSignUp/OrgCreateComponents/WelcomeScreen';
import JoinFromInvite from 'views/NewSignUp/JoinFromInvite';
import Protected from 'layouts/Protected';
import TeamMembers from 'views/POS/TeamMembers';
import AddTeamMember from 'views/POS/TeamMembers/Add';
import Coupons from 'views/POS/Coupons';
import PaymentRequest from 'views/NewSignUp/SubOrgComponents/PaymentRequest';
import ScrollToTop from '../layouts/components/ScrollToTop';
import DomainRoute from '../layouts/Domain';
import Public from '../layouts/Public';

import Private from '../layouts/Private';
import Error404Page from '../layouts/components/404';
import Login from '../views/NewSignUp';
import AuthActions from '../views/NewSignUp/AuthActions';
import { Spinner } from '../components/Spinner';

const WebsiteBuilder = lazy(
	() => import('views/BrandManagement/Websites/New/WebsiteBuilder')
);

const WebPagePreviewPage = lazy(
	() => import('views/BrandManagement/Websites/New/Preview')
);

const CreateWebsite = lazy(
	() => import('../views/BrandManagement/Websites/New')
);
const BookingEventNew = lazy(() => import('../views/Bookings'));
const BookingEvent = lazy(() => import('../views/Bookings/EventBookings'));
const Events = lazy(() => import('../views/Bookings/Orders/Events'));
const Event = lazy(() => import('../views/Bookings/Orders/Event'));
const EventCalendar = lazy(() => import('../views/Bookings/EventCalendar'));
const EcommerceOrders = lazy(
	() => import('../views/BrandManagement/Ecommerce')
);

const SocialMedia = lazy(() => import('../views/BrandManagement/SocialMedia'));

const EditTask = lazy(
	() => import('../views/BrandManagement/SocialMedia/Components/AssignTask/Edit')
);

const ModalPostView = lazy(
	() => import('../views/BrandManagement/SocialMedia/Components/ModalPostView')
);
const FacebookFullPreview = lazy(
	() =>
		import(
			'../views/BrandManagement/SocialMedia/Components/FullPreviews/FacebookFullPreview'
		)
);
const InstagramFullPreview = lazy(
	() =>
		import(
			'../views/BrandManagement/SocialMedia/Components/FullPreviews/InstagramFullPreview'
		)
);
const TwitterFullPreview = lazy(
	() =>
		import(
			'../views/BrandManagement/SocialMedia/Components/FullPreviews/TwitterFullPreview'
		)
);
const LinkedinFullPreview = lazy(
	() =>
		import(
			'../views/BrandManagement/SocialMedia/Components/FullPreviews/LinkedinFullPreview'
		)
);
const SingleChannelCampaigns = lazy(
	() => import('../views/Marketing/SingleChannelCampaigns')
);
const SingleChannelCampaignsNew = lazy(
	() => import('../views/Marketing/SingleChannelCampaigns/New')
);

const NewSegment = lazy(() => import('../views/Marketing/Segments/New'));
const NewTrigger = lazy(() => import('../views/Marketing/Triggers/New'));
const EditSegment = lazy(() => import('../views/Marketing/Segments/Edit'));
const EditTrigger = lazy(() => import('../views/Marketing/Triggers/Edit'));
const DynamicModuleDocuments = lazy(() => import('../views/Dynamic/Main'));
const NewDynamicModuleDocument = lazy(
	() => import('../views/Dynamic/Main/New')
);
const EditDynamicModuleDocument = lazy(
	() => import('../views/Dynamic/Main/Edit')
);

const UsersGroups = lazy(() => import('../views/Admin/UsersGroups'));

const AdminMarketting = lazy(() => import('../views/Admin/Marketting'));

const EditAccountConfig = lazy(
	() => import('../views/Admin/Accounts/AccountUpdate')
);
const NewEmailConfig = lazy(
	() => import('../views/Admin/Marketting/EmailConfig/NewEmailConfig')
);
const SMSConfiguration = lazy(
	() => import('../views/Admin/Marketting/SmsConfig/SMSConfiguration')
);
const VoiceConfiguration = lazy(
	() => import('../views/Admin/Marketting/VoiceConfig/VoiceConfiguration')
);
const NewSentimentConfig = lazy(
	() => import('../views/Admin/Marketting/SentimentConfig/New')
);
const EditSentimentConfig = lazy(
	() => import('../views/Admin/Marketting/SentimentConfig/New')
);

const NewOrganizations = lazy(() => import('../views/Admin/NewOrganization'));
const DefaultOrganization = lazy(
	() => import('../views/Admin/NewOrganization/DefaultOrganization')
);
const DefaultLocation = lazy(
	() => import('../views/Admin/NewOrganization/Locations')
);
const DefaultNewLocation = lazy(
	() => import('../views/Admin/NewOrganization/Locations/NewEditLocation')
);
const SenderProfiles = lazy(() => import('../views/Admin/SenderProfiles'));
const NewSenderProfiles = lazy(
	() => import('../views/Admin/SenderProfiles/NewSenderProfiles')
);
const TwitterCallback = lazy(
	() => import('../views/Admin/Accounts/TwitterCallback')
);
const GoogleCallback = lazy(
	() => import('../views/Admin/Accounts/GoogleCallback')
);

const Analytics = lazy(() => import('../views/Analytics'));
const EmailDetails = lazy(
	() => import('../views/Analytics/Email/EmailDetails')
);
const SMSCampaignDetails = lazy(
	() => import('../views/Analytics/SMS/SMSCampaignDetails')
);
const VoiceCampaignDetails = lazy(
	() => import('../views/Analytics/Voice/VoiceCampaignDetails')
);

const VoiceRecipientDetails = lazy(
	() => import('../views/Analytics/Voice/VoiceRecipientDetails')
);

const SMSRecipientDetails = lazy(
	() => import('../views/Analytics/SMS/SMSRecipientDetails')
);
const EmailCampaignRecipientsDetails = lazy(
	() => import('../views/Analytics/Email/EmailCampaignRecipientsDetails')
);
const DomainLogin = lazy(() => import('../views/DomainLogin'));
const ForgotPassword = lazy(() => import('../views/ForgotPassword'));
const CheckYourEmail = lazy(() => import('../views/CheckYourEmail'));
const Dashboard = lazy(() => import('../views/Dashboard'));
const Creatives = lazy(() => import('../views/Marketing/Creatives'));
const NewSocial = lazy(() => import('../views/Marketing/Creatives/Social/New'));
const SocialTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/SocialTemplates')
);
const FacebookTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/FacebookTemplates/FacebookTemplate'
		)
);
const GoogleTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/GoogleTemplates/GoogleTemplate'
		)
);
const TwitterTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/TwitterTemplates/TwitterTemplate'
		)
);
const LinkedinTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/LinkedinTemplates/LinkedinTemplate'
		)
);
const InstagramTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/InstagramTemplates/InstagramTemplate'
		)
);
const FacebookTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/FacebookTemplates')
);
const InstagramTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/InstagramTemplates')
);
const GoogleTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/GoogleTemplates')
);
const LinkedinTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/LinkedinTemplates')
);
const TwitterTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/TwitterTemplates')
);
const NewSMS = lazy(() => import('../views/Marketing/Creatives/SMS/NewSMS'));
const NewVoice = lazy(
	() => import('../views/Marketing/Creatives/Voice/NewVoice')
);
const SocialViewPostActions = lazy(
	() =>
		import(
			'../views/Marketing/Creatives/Social/components/ViewPost/SocialViewPostActions'
		)
);
const SMSViewPostActions = lazy(
	() => import('../views/Marketing/Creatives/SMS/ViewPost/SMSViewPostActions')
);
const VoiceViewPostActions = lazy(
	() =>
		import('../views/Marketing/Creatives/Voice/ViewPost/VoiceViewPostActions')
);
const EditPost = lazy(
	() => import('../views/Marketing/Creatives/Social/components/EditPost')
);
const EditPostSMS = lazy(
	() => import('../views/Marketing/Creatives/SMS/EditPost')
);

const EditPostVoice = lazy(
	() => import('../views/Marketing/Creatives/Voice/EditPost')
);
const NewCreative = lazy(() => import('../views/Marketing/Creatives/New'));
const EditCreative = lazy(() => import('../views/Marketing/Creatives/Edit'));
const CreativeActions = lazy(
	() => import('../views/Marketing/Creatives/components/CreativeActions')
);
const Segments = lazy(() => import('../views/Marketing/Segments'));
const Triggers = lazy(() => import('../views/Marketing/Triggers'));
const SocialInbox = lazy(() => import('../views/Inbox/Social'));
const CampaignsInbox = lazy(() => import('../views/Inbox/Campaings'));
const CommunicationInbox = lazy(() => import('../views/Inbox/Communication'));
const WorkStations = lazy(() => import('../views/POS/WorkStations'));
const POSAdmin = lazy(() => import('../views/POS/Admin'));
const PIM = lazy(() => import('../views/POS/PIM'));
const EditorPopUP = lazy(() => import('components/ImageEditor/PopUp'));
const PopUpResponse = lazy(
	() => import('components/ImageEditor/PopUpResponse')
);
const DynamicForm = lazy(() => import('../views/Admin/DynamicFields'));
const DynamicForms = lazy(() => import('../views/Admin/DynamicForms'));
const NewDynamicForm = lazy(
	() => import('../views/Admin/DynamicForms/Components/NewDynamicForm')
);

const NewDynamicModule = lazy(() => import('../views/Admin/DynamicFields/New'));

const Main = () => {
	const checkDomainLoginExist = () => {
		if (window.location.host.includes('login')) {
			const index = window.location.host
				?.split('.')
				.findIndex((x) => x === 'login');
			return window.location.host?.split('.')[index];
		}
		return '';
	};

	const CheckLoginDomain = checkDomainLoginExist();

	return (
		<Router>
			<ScrollToTop>
				<Switch>
					{/* Public */}
					{CheckLoginDomain === 'login' && (
						<Route
							render={(props) => <DomainRoute component={DomainLogin} {...props} />}
						/>
					)}
					<Route
						exact
						path='/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={Login} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/signup'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={RegisterForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/auth'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={AuthActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/verifyemail'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={WelcomeScreen} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/join'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={JoinFromInvite} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/ForgotPassword'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={ForgotPassword} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/CheckYourEmail'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={CheckYourEmail} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/payment-request/:token'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={PaymentRequest} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/chooseorg'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Protected component={ChooseOrg} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/createorg'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Protected component={CreateOrg} {...props} />
							</Suspense>
						)}
					/>

					{/* Private */}
					<Route
						exact
						path='/Dashboard'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Dashboard} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Inbox/Social/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialInbox} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Inbox/Campaigns/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CampaignsInbox} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Communication/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CommunicationInbox} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Calendar'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EventCalendar} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Orders/Events'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Events} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Orders/Event/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Event} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Orders/Payment/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Event} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Orders/Guests/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Event} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Builder/Event'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={BookingEvent} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Builder/Event/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={BookingEventNew} {...props} />
							</Suspense>
						)}
					/>
					{/* Wesbites */}
					<Route
						exact
						path='/BrandManagement/Websites'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CreateWebsite} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/BrandManagement/Websites/Website/:website'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={WebsiteBuilder} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/BrandManagement/Websites/Website/:websiteId/:headerId/:footerId/:pageId'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={WebPagePreviewPage} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/BrandManagement/EcommerceOrders'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EcommerceOrders} {...props} />
							</Suspense>
						)}
					/>

					{/* SocilaMedia */}
					<Route
						exact
						path='/BrandManagement/SocialMedia/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialMedia} {...props} />
							</Suspense>
						)}
					/>
					<Route
						path='/BrandManagement/SocialMedia'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialMedia} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/BrandManagement/SocialMedia/Post'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={ModalPostView} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Inbox/Received/Task/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditTask} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Inbox/Sent/Task/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditTask} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/SocialMedia/Posts/FbPost/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<DomainRoute component={FacebookFullPreview} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/SocialMedia/Posts/InstaPost/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<DomainRoute component={InstagramFullPreview} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/SocialMedia/Posts/TwitterPost/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<DomainRoute component={TwitterFullPreview} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/SocialMedia/Posts/LinkedInPost/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<DomainRoute component={LinkedinFullPreview} {...props} />
							</Suspense>
						)}
					/>
					{/* Marketing */}
					<Route
						exact
						path='/Marketing/SingleChannelCampaigns'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SingleChannelCampaigns} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SingleChannelCampaigns/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SingleChannelCampaignsNew} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SingleChannelCampaigns/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SingleChannelCampaignsNew} {...props} />
							</Suspense>
						)}
					/>

					{/* Creatives */}
					<Route
						exact
						path='/Marketing/Creatives'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/SMS'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Voice'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Voice/New/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewVoice} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Email'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Social'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Social/New/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSocial} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/FacebookTemplates/FacebookTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={FacebookTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/GoogleTemplates/GoogleTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={GoogleTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/InstagramTemplates/InstagramTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={InstagramTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/TwitterTemplates/TwitterTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={TwitterTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/LinkedinTemplates/LinkedinTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={LinkedinTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/FacebookTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={FacebookTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/InstagramTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={InstagramTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/GoogleTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={GoogleTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/TwitterTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={TwitterTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/LinkedinTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={LinkedinTemplates} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Marketing/Creatives/Social/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialViewPostActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Social/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditPost} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/SMS/New/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSMS} {...props} />
							</Suspense>
						)}
					/>
					{/* start */}
					<Route
						exact
						path='/Marketing/Creatives/Email/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewCreative} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/SMS/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditPostSMS} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Voice/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditPostVoice} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/SMS/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSViewPostActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Voice/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceViewPostActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Email/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CreativeActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Email/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditCreative} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Marketing/Creatives/Email/edit/:id/:actionType'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditCreative} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Marketing/Creatives/Email/view/:tempType/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CreativeActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Segments'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Segments} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Segments/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSegment} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Segments/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditSegment} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Triggers'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Triggers} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Triggers/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewTrigger} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Triggers/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditTrigger} {...props} />
							</Suspense>
						)}
					/>
					{/* Customers */}
					<Route
						exact
						path='/Dynamic/:moduleName/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DynamicModuleDocuments} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Dynamic/:moduleName/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDynamicModuleDocument} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Dynamic/:moduleName/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditDynamicModuleDocument} {...props} />
							</Suspense>
						)}
					/>

					{/* Business */}

					{/* Admin */}
					<Route
						exact
						path='/Admin/UsersGroups/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={UsersGroups} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Marketing'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AdminMarketting} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/AccountConfig/:id/Edit'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditAccountConfig} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/EmailConfig/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewEmailConfig} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/EmailConfig/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewEmailConfig} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/SmsConfig/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSConfiguration} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/SmsConfig/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSConfiguration} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/VoiceConfig/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceConfiguration} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/VoiceConfig/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceConfiguration} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/SentimentConfig/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSentimentConfig} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/SentimentConfig/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditSentimentConfig} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/NewOrganization'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewOrganizations} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/NewOrganization/Organization'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewOrganizations} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/DynamicForm'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DynamicForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/NewDynamicModule'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDynamicModule} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Dynamic_Form'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DynamicForms} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Dynamic_Form/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDynamicForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Dynamic_Form/:moduleName/Edit'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDynamicForm} {...props} />
							</Suspense>
						)}
					/>
					{/* // New Flow */}
					<Route
						exact
						path='/Admin/Organization/DefaultOrg'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DefaultOrganization} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Organization/Locations'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DefaultLocation} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Organization/Locations/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DefaultNewLocation} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Organization/Locations/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DefaultNewLocation} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/SenderProfiles'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SenderProfiles} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/NewSenderProfile/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSenderProfiles} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/NewSenderProfile/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSenderProfiles} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/linkedin'
						render={() => (
							<Suspense fallback={<Spinner />}>
								<LinkedInCallback />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/twitter'
						render={() => (
							<Suspense fallback={<Spinner />}>
								<TwitterCallback />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/google'
						render={() => (
							<Suspense fallback={<Spinner />}>
								<GoogleCallback />
							</Suspense>
						)}
					/>

					{/* Analytics */}
					<Route
						exact
						path='/Analytics/Campaigns'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Analytics} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/Email'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Analytics} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/SMS'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Analytics} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/Voice'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Analytics} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/:id/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EmailDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/SMS/:id/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSCampaignDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/SMS/:id/:messageId/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSRecipientDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/Voice/:id/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceCampaignDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/Voice/:id/:messageId/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceRecipientDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/:id/:messageId/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EmailCampaignRecipientsDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/work-stations'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={WorkStations} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/payment-terminals'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PaymentTerminals} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/coupons'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Coupons} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/team-members'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={TeamMembers} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/team-members/add'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddTeamMember} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/admin'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={POSAdmin} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/pim'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIM} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/ImageEditor'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditorPopUP} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/ImageEditorResponse'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PopUpResponse} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/404'
						render={(props) => <DomainRoute component={Error404Page} {...props} />}
					/>
					<Route
						render={(props) => <DomainRoute component={Error404Page} {...props} />}
					/>
				</Switch>
			</ScrollToTop>
		</Router>
	);
};

export default Main;
