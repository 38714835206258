import {
	SET_BOOKING_NAME,
	SET_BOOKING_DESCRIPTION,
	BOOKINGS_UPDATE_VIEW,
	SET_SELECTED_LOCATIONS,
	SET_CHECKED_PACKAGES,
	SET_FOOD_ADD_ON_PRODUCTS,
	SET_DECORATIVE_ADD_ON_PRODUCTS,
	SET_CUSTOMERINFORMATION,
	RESET_BOOKINGS,
} from './BookingTypes';

interface IBookings {
	BookingName: string;
	BookingDescription: string;
	currentView: number;
	locationArray: any;
	checkedselectedLocation: any;
	selectedFoodAddOnProducts: [];
	selcetedDecorativeAddOnProducts: [];
	customerInformation: any;
	checkedselectedPackages: any;
}

const initialState: IBookings = {
	BookingName: '',
	BookingDescription: '',
	currentView: 0,
	checkedselectedLocation: [],
	checkedselectedPackages: [],
	locationArray: {
		selectedLocations: [],
	},
	selectedFoodAddOnProducts: [],
	selcetedDecorativeAddOnProducts: [],
	customerInformation: {
		customerValue: 'Contacts',
		checkedSelectedFields: [],
	},
};

const bookingsReducer = (state: any = initialState, action): IBookings => {
	switch (action.type) {
		case SET_BOOKING_NAME:
			return { ...state, BookingName: action.payload };
		case SET_BOOKING_DESCRIPTION:
			return { ...state, BookingDescription: action.payload };
		case BOOKINGS_UPDATE_VIEW:
			return { ...state, currentView: action.payload };
		case SET_SELECTED_LOCATIONS:
			return { ...state, checkedselectedLocation: action.payload };
		case SET_CHECKED_PACKAGES:
			return { ...state, checkedselectedPackages: action.payload };
		case SET_FOOD_ADD_ON_PRODUCTS:
			return { ...state, selectedFoodAddOnProducts: action.payload };
		case SET_DECORATIVE_ADD_ON_PRODUCTS:
			return { ...state, selcetedDecorativeAddOnProducts: action.payload };
		case SET_CUSTOMERINFORMATION:
			return { ...state, customerInformation: action.payload };
		case RESET_BOOKINGS:
			return { ...initialState };
		default:
			return state;
	}
};

export default bookingsReducer;
