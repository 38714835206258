import MUIGridGlobal from 'components/Table/MUIGlobalGrid';

import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';

import TypographyComponent from 'storybook-mui/build/components/Typography';
import {
	GetPosPaymentTerminals,
	RefreshPosPaymentTerminals,
} from 'firebaseApis/pos_payment_terminals';
import Button from 'storybook-mui/build/components/Button';

function PaymentTerminals() {
	const dispatch = useDispatch();

	const [paymentTerminals, setPaymentTerminals] = useState<any>([]);

	const getPaymentTerminals = async () => {
		dispatch(showLoading());
		const result = await GetPosPaymentTerminals();
		setPaymentTerminals(result.data ?? []);
		dispatch(hideLoading());
	};

	const refreshTerminals = async () => {
		dispatch(showLoading());
		await RefreshPosPaymentTerminals();
		getPaymentTerminals();
		dispatch(hideLoading());
	};

	const columns = [
		{
			headerName: 'Payment_Terminal_Id',
			field: 'Payment_Terminal_Id',
			flex: 1,
		},
		{
			headerName: 'POI State',
			field: 'POIState',
			flex: 1,
		},
		{
			headerName: 'Serial Number',
			field: 'serialNumber',
			flex: 1,
		},
	];

	useEffect(() => {
		getPaymentTerminals();
	}, []);

	return (
		<>
			<div className='my-2 flex items-center justify-center md:justify-start'>
				<TypographyComponent
					label='Payment Terminals'
					id='Payment Terminals'
					variant='h4'
					sx={{
						fontSize: {
							lg: 30,
							xs: 20,
						},
					}}
				/>
			</div>

			<div className='flex items-center justify-end my-2'>
				<Button
					title='Refresh'
					onClick={() => refreshTerminals()}
					variant='contained'
				/>
			</div>

			<div>
				<MUIGridGlobal
					columns={columns}
					dataSource={paymentTerminals ?? []}
					rowCount={paymentTerminals?.length}
					noRecordsText='No Payment Terminals Found'
				/>
			</div>
		</>
	);
}

export default PaymentTerminals;
