import Slide from '@mui/material/Slide';
import { GetAllIndustries } from 'firebaseApis/authentication';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'storybook-mui/build/components/Button';
import IndustryTypesImg from 'assets/img/industry-types.svg';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import Toast, { ToastTypes } from 'components/ToastNotify';
import AutocompleteField from 'storybook-mui/build/components/Autocomplete';
import {
	SetNewOrgData,
	SetNewOrgFlowStep,
} from 'reducers/CreateOrg/CreateOrgActions';
import { INewOrgInitialState } from 'reducers/CreateOrg';

function IndustrySelection() {
	const dispatch = useDispatch();
	const newOrgStore: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);
	const [industries, setIndustries] = useState<[]>([]);

	const getIndustries = async () => {
		try {
			dispatch(showLoading());
			const result = await GetAllIndustries();
			const industryList = result.filter((item) => item.Display);
			const dataSource = industryList.map((item: any) => ({
				label: item.Name,
				id: item.id,
			}));

			if (newOrgStore.Org_Data.Industry && newOrgStore.Org_Flow_Type === 'SUB') {
				dispatch(
					SetNewOrgData({
						...newOrgStore.Org_Data,
						Industry: JSON.stringify(
							dataSource.find(
								(item: any) => item.label === newOrgStore.Org_Data.Industry
							)
						),
					})
				);
			}
			setIndustries(dataSource);
			dispatch(hideLoading());
		} catch (error) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			dispatch(hideLoading());
		}
	};

	useEffect(() => {
		getIndustries();
		dispatch(increaseProgressVal(37));
	}, []);

	const continueChangehandler = () => {
		dispatch(SetNewOrgFlowStep(newOrgStore.Step + 1));
	};

	const onIndustryChange = (industry: any) => {
		dispatch(
			SetNewOrgData({
				...newOrgStore.Org_Data,
				Industry: JSON.stringify(industry),
			})
		);
	};

	return (
		<div className='w-full mx-auto'>
			<Slide direction='left' in mountOnEnter unmountOnExit>
				<div className='flex flex-col items-center justify-center pb-5'>
					<div className='flex flex-col lg:flex-row items-center'>
						<div
							className='flex flex-col w-full col-span-2 bg-white p-4 rounded-xl industryPage-left-ct bg-no-repeat bg-right bg-contain bg-origin-content'
							style={{
								backgroundImage: `url(${IndustryTypesImg})`,
							}}
						>
							<div className='h-full lg:pl-8 flex flex-col justify-center w-full lg:w-11/12'>
								<h1 className='text-xl xl:text-2xl font-semibold uppercase mb-4 lg:mb-8 text-info-600 whitespace-wrap lg:whitespace-nowrap'>
									What industry best describes your business?
								</h1>
								<div className='w-full lg:w-3/5'>
									{industries.length > 0 && (
										<AutocompleteField
											placeholder='SELECT YOUR INDUSTRY'
											fieldId='Industry'
											{...(newOrgStore.Org_Data.Industry && {
												value: JSON.parse(newOrgStore.Org_Data.Industry),
											})}
											options={industries}
											onChange={(e: any, v: any) => onIndustryChange(v)}
										/>
									)}
								</div>
							</div>
						</div>
						<div className='flex text-center items-center p-2 lg:p-16'>
							<p className='text-lg text-dark-600 lg:text-xl'>
								Your industry tells us how to structure your contacts database & what
								creative templates to suggest.
							</p>
						</div>
					</div>
					<div className='bottom-section w-full text-center mt-8 lg:mt-20'>
						<Button
							disabled={newOrgStore.Org_Data.Industry === ''}
							title='continue'
							size='large'
							onClick={continueChangehandler}
						/>
					</div>
				</div>
			</Slide>
		</div>
	);
}

export default IndustrySelection;
