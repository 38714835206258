export const SET_VOICE_RESPONSE = 'SET_VOICE_RESPONSE';
export const SET_VOICE_TREE_DATA = 'SET_VOICE_TREE_DATA';
export const SET_VOICE_VIEW = 'SET_VOICE_VIEW';
export const SET_VOICE_MAIN_MESSAGE = 'SET_VOICE_MAIN_MESSAGE';
export const RESET_VOICE_CREATIVE = 'RESET_VOICE_CREATIVE';
export const SET_VOICE_REPLY = 'SET_VOICE_REPLY';
export const SET_VOICE_NODE_ID = 'SET_VOICE_NODE_ID';
export const ADD_VOICE_REPLY = 'ADD_VOICE_REPLY';
export const SET_VOICE_SPEAKER_LANGUAGES = 'SET_VOICE_SPEAKER_LANGUAGES';
export const SET_VOICE_SPEAKERS = 'SET_VOICE_SPEAKERS';
export const SET_VOICE_CREATIVE_NAME = 'SET_VOICE_CREATIVE_NAME';
export const SET_VOICE_SELECTED_CATEGORY = 'SET_VOICE_SELECTED_CATEGORY';
export const SET_VOICE_CATEGORIES = 'SET_VOICE_CATEGORIES';
export const SET_VOICE_SELECTED_SUBCATEGORY = 'SET_VOICE_SELECTED_SUBCATEGORY';
export const SET_VOICE_MAIN_MESSAGE_HAVE_DYNAMIC =
	'SET_VOICE_MAIN_MESSAGE_HAVE_DYNAMIC';
