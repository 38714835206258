import { SHOW_LOADER, HIDE_LOADER, HIDE_LINEAR_LOADER, SHOW_LINEAR_LOADER } from './AlertActionTypes';

export const showLoading = () => async (dispatch) => {
	dispatch({ type: SHOW_LOADER });
};

export const showLinearLoading = () => async (dispatch) => {
	dispatch({ type: SHOW_LINEAR_LOADER });
};
export const hideLoading = () => async (dispatch) => {
	dispatch({ type: HIDE_LOADER });
};
export const hideLinearLoading = () => async (dispatch) => {
	dispatch({ type: HIDE_LINEAR_LOADER });
};
