import React from 'react';

import { useSelector } from 'react-redux';

import { Spinner } from 'components/Spinner';
import { RootStore } from 'reducers';

import 'layouts/components/welcome-layout.scss';

import Leap360Logo from 'assets/img/leap360.png';
import Loginform from './Loginform';

export const Login = () => {
	const { loading } = useSelector((state: RootStore) => state.alertsReducer);

	return (
		<div
			className='h-screen w-auto flex justify-center items-center px-4 lg:px-0'
			style={{ backgroundColor: '#D1D5DB' }}
		>
			{loading && <Spinner />}

			<div className='shadow-custom bg-white p-4 rounded-md'>
				<div className='flex justify-center items-center w-1/2 lg:w-64 lg:h-64 lg:-mt-60 mx-auto'>
					<img src={Leap360Logo} className='w-full' />
				</div>
				<p className='text-center font-extrabold text-3xl  my-2  text-secondary-500'>
					WELCOME
				</p>
				<hr />
				<Loginform />
			</div>
		</div>
	);
};

export default Login;
