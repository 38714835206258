import React, { useEffect } from 'react';
import { getUser } from 'api/AxiosManager';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { GetOrganisationDetailsById } from 'firebaseApis/organization';
import { GetOrganisationOfUser } from 'firebaseApis/authentication';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'components/Spinner';
import { RootStore } from '../reducers';

export function PublicRoute({ component: Component, ...props }: any) {
	const history = useHistory();
	const user = getUser();
	const { loading } = useSelector((state: RootStore) => state.alertsReducer);
	const dispatch = useDispatch();

	const redirectToCreateOrChooseOrgPage = async () => {
		const { userId } = user;
		try {
			dispatch(showLoading());
			const resultData = await GetOrganisationOfUser(userId);
			dispatch(hideLoading());
			if (resultData.length === 0) {
				history.push('/createorg');
			} else {
				history.push('/chooseorg');
			}
		} catch (err) {
			dispatch(hideLoading());
			history.push('/');
		}
	};

	async function checkUserAndOrgIsValidOrNot() {
		if (
			Object.keys(user).length &&
			user?.organisationPathFirebase === undefined
		) {
			redirectToCreateOrChooseOrgPage();
		}
		if (
			Object.keys(user).length &&
			user?.organisationPathFirebase !== undefined
		) {
			try {
				dispatch(showLoading());
				const response = await GetOrganisationDetailsById(
					user.organisationPathFirebase
				);
				dispatch(hideLoading());
				if (Object.keys(response).length === 0) {
					return history.push('/createorg');
				}
				return history.push('/Marketing/SingleChannelCampaigns/');
			} catch (error) {
				dispatch(hideLoading());
				return history.push('/createorg');
			}
		}
		return null;
	}

	useEffect(() => {
		checkUserAndOrgIsValidOrNot();
	}, []);
	return (
		<>
			{loading && <Spinner />}
			<Component {...props} />
		</>
	);
}

export default PublicRoute;
