import * as Yup from 'yup';

export const formikValidationHelpers = {
	stringRequired: Yup.string().required('Required'),
	stringRequiredWithSpecialCharctersExclude: Yup.string()
		.required('Required')
		.matches(
			/^[^'"/|\\\`?\s]+( [^'"/|\\\`?]+)*$/,
			'special characters not allowed: \' \\ / ? ` " |'
		),
	stringWithSpecialCharctersExclude: Yup.string()
		.matches(
			/^[^'"/|\\\`?\s]+( [^'"/|\\\`?]+)*$/,
			'special characters not allowed: \' \\ / ? ` " |'
		),
	stringRequiredAlphaNumeric: Yup.string()
		.required('Required')
		.matches(
			/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
			'Special characters not allowed'
		),
	stringRequiredOnlyAlpha: Yup.string()
		.required('Required')
		.matches(/^[a-zA-Z]+( [a-zA-Z]+ )*$/, 'Only Alphabets are allowed'),
	stringRequiredOnlyNumbers: Yup.string()
		.required('Required')
		.matches(/^[0-9]*$/, 'Only numbers are allowed'),
	zipCode: Yup.string()
		.required('Required')
		.matches(/^[0-9]+$/, 'Only numbers are allowed')
		.min(5, 'Zip code atleast 5 digits'),
	phoneNumber: Yup.string()
		.required('Required')
		.matches(/^[0-9]+$/, 'Only numbers are allowed')
		.length(10, 'Phonenumber should be 10 digits'),

	phoneNumberWithoutRequired: Yup.string()
		.matches(/^[0-9]+$/, 'Only numbers are allowed')
		.length(10, 'Invalid Phone Number'),
	email: Yup.string().email('Invalid email').required('Required'),
	emailWithoutRequired: Yup.string().email('Invalid email'),
};
