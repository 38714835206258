import React, { useEffect, useState } from 'react';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { INewOrgInitialState } from 'reducers/CreateOrg';
import { useFormik } from 'formik';
import { CancelNewOrgFlow, SetNewOrgFlowStep } from 'reducers/CreateOrg/CreateOrgActions';
import Cards from 'react-credit-cards';
import { useDispatch, useSelector } from 'react-redux';
import SelectField from 'storybook-mui/build/components/Select';
import InputMask from 'react-input-mask';
import { getUser, siteConfigConstants } from 'api/AxiosManager';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import LinearProgressBar from 'components/LinearProgressBar';
import {
	CreateOrganisation,
	GetAllCountries,
	GetOrganisationInfoById,
	MakeVerifonePayment,
} from 'firebaseApis/authentication';
import Tabs from 'storybook-mui/build/components/Tabs';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import InputTextField from 'storybook-mui/build/components/TextField';
import AutocompleteField from 'storybook-mui/build/components/Autocomplete';
import { credicardValidation } from '../validations/paymentValidation';
import 'react-credit-cards/es/styles-compiled.css';
import { IUserDataInLocalStorage } from '../onboardInterfaces';

function Payment() {
	const dispatch = useDispatch();
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [isSecondTabEnabled, setIsSecondTabEnabled] = useState(false);
	const [countries, setCountries] = useState([]);
	const [countryCodes, setCountryCodes] = useState([]);
	const [validateFormOnChange, setValidateFormOnChange] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);
	const [focus, setFocus] = useState('');
	const newOrgStore: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);
	const userInLocalStorage: IUserDataInLocalStorage = getUser();
	const handlingResponseError = (resp) => {
		const code = resp;
		let msg = 'Payment failed';
		if (code === 500) {
			msg =
				'Unexpected error: if the error persists, please contact an administrator';
		} else if (code === 504) {
			msg =
				'Timeout error: request was terminated by timeout to third party service';
		}
		Toast({ title: `${msg}`, type: ToastTypes.ERROR });
	};

	const cardForm = useFormik({
		initialValues: {
			number: '',
			name: `${userInLocalStorage.fName} ${userInLocalStorage.lName}`,
			expiryDate: '',
			cvv: '',
			issuer: '',
			firstName: `${userInLocalStorage.fName}`,
			lastName: `${userInLocalStorage.lName}`,
			city: `${newOrgStore.Org_Data.User_City}`,
			state: `${newOrgStore.Org_Data.User_State}`,
			zip: '',
			phoneNumber: `${userInLocalStorage.phoneNumber}`,
			email: `${userInLocalStorage.email}`,
			addressLine1: '',
			addressLine2: '',
			select_Phone_Type: 'Mobile',
			landline_ext: `${userInLocalStorage.landline_ext}`,
			phone_countryCode: `${userInLocalStorage.countryCode}`,
		},
		validationSchema: credicardValidation,
		validateOnChange: validateFormOnChange,
		onSubmit: async (values) => {
			try {
				setLoading(true);
				const newWindowObject = window as any;
				const { verifone } = newWindowObject;
				const encryptionKey =
					siteConfigConstants().REACT_APP_VERIFONE_ENCRYPTION_KEY;
				const card = {
					cardNumber: values.number,
					expiryMonth: values.expiryDate.split('/')[0],
					expiryYear: values.expiryDate.split('/')[1],
					name: values.name,
					cvc: values.cvv,
				};
				const encryptedCard = await verifone.encryptCard(card, encryptionKey);

				const paymentResponse: any = await MakeVerifonePayment({
					newOrgData: newOrgStore,
					cardValues: values,
					Verifone_Card_Token: encryptedCard,
				});
				if (paymentResponse?.status === 'AUTHORIZED') {
					const response = await CreateOrganisation({
						newOrgData: newOrgStore,
						cardValues: values,
						paymentInfo: paymentResponse,
					});
					const orgTitle = 'Organization';

					if (response.data && response.data?.Organization_Id) {
						Toast({
							title: `${orgTitle} Created Successfully`,
							type: ToastTypes.SUCCESS,
						});
						await GetOrganisationInfoById(response.data?.Organization_Id);
						dispatch(SetNewOrgFlowStep(newOrgStore.Step + 1));
						setCurrentProgress(100);
					} else if (response.data && response.data?.success === false) {
						Toast({
							title: `${orgTitle} name already exists`,
							type: ToastTypes.ERROR,
						});
						setCurrentProgress(0);
					} else {
						Toast({ title: `${orgTitle} creation failed`, type: ToastTypes.ERROR });
					}
				} else {
					if (paymentResponse.name === 'Error') {
						const errormsg = paymentResponse.message;
						const errcode = errormsg && errormsg.match(/\d+/g);
						const errorcodenum = errcode.length > 0 ? parseInt(errcode[0], 10) : '';
						handlingResponseError(errorcodenum);
					} else {
						Toast({ title: 'Payment failed', type: ToastTypes.ERROR });
					}
					setCurrentProgress(0);
				}
				setLoading(false);
			} catch (error) {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
				setLoading(false);
			}
		},
	});

	const handleCallback = (data) => {
		cardForm.setFieldValue('issuer', data.issuer);
	};

	const onProgressComplete = async () => {
		await new Promise((resolve) => setTimeout(resolve, 300));
		setLoading(false);
		setCurrentProgress(0);
		dispatch(CancelNewOrgFlow());
	};
	useEffect(() => {
		if (validateFormOnChange) {
			if (
				cardForm.errors.firstName !== undefined ||
				cardForm.errors.lastName !== undefined ||
				cardForm.errors.city !== undefined ||
				cardForm.errors.state !== undefined ||
				cardForm.errors.zip !== undefined ||
				cardForm.errors.phoneNumber !== undefined ||
				cardForm.errors.addressLine1 !== undefined ||
				cardForm.errors.addressLine2 !== undefined
			) {
				setActiveTabIndex(0);
				setIsSecondTabEnabled(false);
			} else {
				setIsSecondTabEnabled(true);
			}
		} else {
			setIsSecondTabEnabled(true);
		}
	}, [cardForm]);

	useEffect(() => {
		if (currentProgress === 100) {
			onProgressComplete();
		}
	}, [currentProgress]);
	const getStates = () => {
		const country = countries.find(
			(c) => c.value === JSON.parse(newOrgStore.Org_Data.Country).value
		);
		const states = [];
		country?.states?.forEach((state) => {
			states.push({
				value: state.name,
				label: state.name,
			});
		});
		return states ?? [];
	};

	const getData = () => {
		GetAllCountries().then((res: any) => {
			const tempCountryNames: any = [];
			const tempCountryCodes: any = [];
			const response: any = res;
			response.forEach((obj: any) => {
				tempCountryCodes.push({
					value: `${obj.phone_code}`,
					label: `${obj.phone_code}`,
					renderLabel: (
						<div className='flex items-center'>
							<span className='h-8 -mb-2'>
								<img src={obj.flag_svg_url} className='h-6 w-6 mx-2' />
							</span>
							<span className='ml-1'>{`${obj.phone_code}`} </span>
						</div>
					),
				});
			});

			setCountryCodes(tempCountryCodes);
			response.forEach((obj: any) => {
				tempCountryNames.push({
					value: `${obj.country_id}`,
					label: obj.country_name,
					id: obj.country_id,
					states: obj.states,
					renderLabel: (
						<div className='flex items-center'>
							<span className='h-8 -mb-2'>
								<img src={obj.flag_svg_url} className='h-6 w-6 mx-2' />
							</span>
							<span className='ml-1'>{obj.country_name} </span>
						</div>
					),
				});
			});
			setCountries(tempCountryNames);
		});
	};

	useEffect(() => {
		getData();
		dispatch(increaseProgressVal(100));
	}, []);

	useEffect(() => {
		cardForm.setFieldValue(
			'name',
			`${cardForm.values.firstName} ${cardForm.values.lastName}`
		);
	}, [cardForm.values.firstName, cardForm.values.lastName]);

	if (loading) {
		return (
			<LinearProgressBar
				delay={50000}
				incrementBy={1}
				currentProgress={currentProgress}
				onProgressComplete={onProgressComplete}
				message='Please be patient while we setup your Business/Organization. This may take a couple of minutes...'
			/>
		);
	}

	const Billing = () => (
		<>
			<div className='pt-3'>
				<h3 className='text-base xl:text-xl text-info-500 font-bold uppercase mb-2'>
					Your name
				</h3>
				<div className='flex gap-4'>
					<InputTextField
						name='firstName'
						labelName='First Name'
						fieldId='firstName'
						onChange={cardForm.handleChange}
						value={cardForm.values.firstName}
						errorMessage={cardForm.errors.firstName}
						valid={cardForm.errors.firstName === undefined}
					/>
					<InputTextField
						name='lastName'
						labelName='Last Name'
						fieldId='name'
						onChange={cardForm.handleChange}
						value={cardForm.values.lastName}
						errorMessage={cardForm.errors.lastName}
						valid={cardForm.errors.lastName === undefined}
					/>
				</div>
			</div>
			<div className='mt-5'>
				<h3 className='text-left mb-2 text-info-500 font-bold uppercase'>
					Billing Address
				</h3>
				<div className='flex flex-col w-full gap-4'>
					<InputTextField
						name='addressLine1'
						labelName='Address Line 1'
						fieldId='addressLine1'
						size='small'
						onChange={cardForm.handleChange}
						value={cardForm.values.addressLine1}
						errorMessage={cardForm.errors.addressLine1}
						valid={cardForm.errors.addressLine1 === undefined}
					/>
					<InputTextField
						name='addressLine2'
						labelName='Address Line 2'
						fieldId='addressLine2'
						onChange={cardForm.handleChange}
						size='small'
						value={cardForm.values.addressLine2}
						errorMessage={cardForm.errors.addressLine2}
						valid={cardForm.errors.addressLine2 === undefined}
					/>
				</div>
				<div className='flex gap-4 mt-5'>
					<InputTextField
						name='city'
						labelName='City'
						fieldId='city'
						onChange={cardForm.handleChange}
						value={cardForm.values.city}
						errorMessage={cardForm.errors.city}
						size='small'
						valid={cardForm.errors.city === undefined}
					/>
				</div>
				<div className='flex gap-4 mt-5'>
					{countries.length > 0 && (
						<AutocompleteField
							{...(cardForm.values.state !== '' && {
								value: getStates().find(
									(item: any) => item.value === cardForm.values.state
								),
							})}
							onChange={(event: any, option: any) =>
								cardForm.setFieldValue('state', option.value)
							}
							options={getStates() ?? []}
							fieldId='state'
							placeholder='State'
							size='small'
							valid={cardForm.errors.state === undefined}
							errorMessage={cardForm.errors.state}
						/>
					)}
					<InputTextField
						name='zip'
						size='small'
						labelName='Zip'
						fieldId='zip'
						onChange={cardForm.handleChange}
						value={cardForm.values.zip}
						errorMessage={cardForm.errors.zip}
						valid={cardForm.errors.zip === undefined}
					/>
				</div>
			</div>
			<div className='mt-5'>
				<h3 className='text-left text-info-500 font-bold uppercase mb-2'>
					Contact Information
				</h3>
				<div className='flex gap-4 mb-5'>
					<InputTextField
						name='email'
						fieldId='email'
						onChange={cardForm.handleChange}
						value={cardForm.values.email}
						errorMessage={cardForm.errors.email}
						valid={cardForm.errors.email === undefined}
						labelName='Email Address'
					/>
				</div>
				<div className='flex flex-col gap-4 relative'>
					<div className='grid grid-cols-1 lg:grid-cols-6'>
						<div className='col-span-4'>
							<div className='grid grid-cols-3 gap-4'>
								<div className='col-span-2'>
									<SelectField
										placeholder='PhoneType'
										value={cardForm.values.select_Phone_Type}
										options={[
											{ label: 'Mobile', value: 'Mobile' },
											{ label: 'Landline', value: 'Landline' },
										]}
										name='select_Phone_Type'
										fieldId='selectPhoneType'
										displayField='label'
										valueField='value'
										onChange={cardForm.handleChange}
										errorMessage='Required'
									/>
								</div>
								{countryCodes.length > 0 && (
									<AutocompleteField
										{...(cardForm.values.phone_countryCode && {
											value: countryCodes.find(
												(item: any) => item.value === cardForm.values.phone_countryCode
											),
										})}
										onChange={(event: any, option: any) => {
											cardForm.setFieldValue('phone_countryCode', option.value);
										}}
										options={countryCodes}
										optionsType='render'
										fieldId='countryCode'
										placeholder='Country code'
									/>
								)}
							</div>
						</div>
						<div />
					</div>
					<div className='grid grid-cols-1 lg:grid-cols-6'>
						<div className='lg:col-span-4'>
							<div className='grid grid-cols-3 gap-4'>
								<div className='col-span-2'>
									<InputTextField
										name='phoneNumber'
										fieldId='phoneNumber'
										onChange={cardForm.handleChange}
										value={cardForm.values.phoneNumber}
										errorMessage={cardForm.errors.phoneNumber}
										valid={cardForm.errors.phoneNumber === undefined}
										labelName='Phone Number'
										type='number'
									/>
								</div>

								{cardForm.values.select_Phone_Type === 'Landline' && (
									<InputTextField
										fieldId='landline_ext'
										name='landline_ext'
										value={cardForm.values.landline_ext}
										onChange={cardForm.handleChange}
										labelName='ext.'
										type='number'
										valid={cardForm.errors.landline_ext === undefined}
										errorMessage={cardForm.errors.landline_ext}
									/>
								)}
							</div>
						</div>
						<div />
					</div>
					<div className='submit-btn-ct'>
						<Button
							title={<h3 className='text-base xl:text-xl uppercase'>Next</h3>}
							variant='contained'
							disabled={!isSecondTabEnabled}
							onClick={() => setActiveTabIndex(1)}
							startIcon={
								<Icon
									icon={IconTypes.Transfer}
									className='text-white hidden lg:block'
								/>
							}
						/>
					</div>
				</div>
			</div>
		</>
	);

	const Card = () => (
		<div className='flex flex-col space-y-5 mt-5'>
			<InputTextField
				labelName='Card Number'
				name='number'
				fieldId='number'
				onChange={cardForm.handleChange}
				value={cardForm.values.number}
				type='number'
				errorMessage={cardForm.errors.number}
				valid={cardForm.errors.number === undefined}
				onFocus={() => setFocus('number')}
			/>
			<InputTextField
				name='name'
				labelName='Card Holder Name'
				fieldId='name'
				onChange={cardForm.handleChange}
				value={cardForm.values.name}
				errorMessage={cardForm.errors.name}
				valid={cardForm.errors.name === undefined}
				onFocus={() => setFocus('name')}
			/>

			<div className='flex space-x-5'>
				<InputMask
					mask='99/99'
					value={cardForm.values.expiryDate}
					disabled={false}
					maskChar=''
					onChange={(e: any) => cardForm.setFieldValue('expiryDate', e.target.value)}
				>
					{() => (
						<div className='flex flex-col'>
							<InputTextField
								labelName='MM/YY'
								name='expiryDate'
								fieldId='expiryDate'
								onChange={() => {}}
								value={cardForm.values.expiryDate}
								errorMessage={cardForm.errors.expiryDate}
								valid={cardForm.errors.expiryDate === undefined}
							/>{' '}
						</div>
					)}
				</InputMask>

				<div>
					<InputTextField
						name='cvv'
						type='number'
						labelName='CVV'
						fieldId='cvv'
						onChange={cardForm.handleChange}
						value={cardForm.values.cvv}
						valid={cardForm.errors.cvv === undefined}
						errorMessage={cardForm.errors.cvv}
						onFocus={() => setFocus('cvc')}
					/>
				</div>
			</div>

			<div className='mt-5 flex justify-between'>
				<Button
					title='PREV'
					onClick={() => setActiveTabIndex(0)}
					type='submit'
					size='large'
					startIcon={<Icon icon={IconTypes.KeyboardDoubleArrowLeft} />}
				/>
				<Button
					title={
						<h3>
							{newOrgStore.Org_Flow_Type === 'SUB'
								? 'Create Sub-Organization'
								: 'Start My Free Trial'}
						</h3>
					}
					disabled={validateFormOnChange && Object.keys(cardForm.errors).length > 0}
					onClick={() => setValidateFormOnChange(true)}
					type='submit'
					size='large'
					endIcon={<Icon icon={IconTypes.KeyboardDoubleArrowRight} />}
				/>
			</div>
		</div>
	);
	return (
		<div className='w-full mx-auto px-4 payment-page-ct'>
			<div className='lg:h-100 grid grid-cols-1 lg:grid-cols-2 lg:gap-16 justify-center gap-y-8 pb-5'>
				<div className='flex flex-col gap-2 w-full left-page-ct'>
					<h2 className='text-2xl text-center lg:text-3xl font-bold text-primary-800 uppercase'>
						We’re not charging you now.{' '}
					</h2>
					<h4 className='text-xl text-center lg:text-2xl text-gray-500'>
						We only charge based on usage after your trial ends.
					</h4>
					<div className='w-full mb-4 lg:mb-10 mt-5 flex justify-center'>
						<Button
							title={<h3 className='text-info-500'>Estimate your custom price</h3>}
							variant='text'
							color='inherit'
							type='button'
							size='medium'
							sx={{ bgColor: '#fff' }}
						/>
					</div>
					<div className='rccs-cc-ct flex justify-center items-center'>
						<Cards
							cvc={cardForm.values.cvv}
							expiry={cardForm.values.expiryDate}
							name={cardForm.values.name}
							number={cardForm.values.number}
							callback={handleCallback}
							focused={focus}
						/>
					</div>
				</div>
				<div className='flex flex-col w-full bg-white shadow-md px-4 py-6'>
					<form className='flex flex-col gap-4' onSubmit={cardForm.handleSubmit}>
						<Tabs
							TabsTiles={['My Information', 'Card Information']}
							{...(!isSecondTabEnabled && { disabledIndex: 1 })}
							TabsContent={[Billing(), Card()]}
							selectedIndex={activeTabIndex}
							setSelectedIndex={setActiveTabIndex}
							variant='fullWidth'
							centered
						/>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Payment;
