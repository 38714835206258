import {
	DYNAMIC_FORMULA_CREATION,
	SELECT_SCRATCH_TEMPLATE,
	DYNAMIC_FORMULA_CURRENT_POSITION,
	DYNAMIC_VARIABLE_QRCODE_PROPERTIES,
	EMOJI_GROUPS,
	EMOJIS,
} from './EmailBuilderActionTypes';

interface IBuilder {
	selectedTemplate: {
		TemplateId: string;
		data: any;
	};
	DynamicFormula: string;
	DynamicFormulaCurrentPosition: {
		curserPosition: number;
		type: string;
	};
	DynamicVariableQRCode: string;
	Emojis: {
		data: any;
		groups: any;
	};
}

const initialState: IBuilder = {
	selectedTemplate: {
		TemplateId: '',
		data: '',
	},
	DynamicFormula: '',
	DynamicFormulaCurrentPosition: {
		curserPosition: 0,
		type: '',
	},
	DynamicVariableQRCode: '',
	Emojis: {
		data: [],
		groups: [],
	},
};

const emailBuilder = (state: any = initialState, action): IBuilder => {
	switch (action.type) {
		case SELECT_SCRATCH_TEMPLATE:
			return { ...state, selectedTemplate: action.payload };
		case DYNAMIC_FORMULA_CREATION:
			return { ...state, DynamicFormula: action.payload };
		case DYNAMIC_FORMULA_CURRENT_POSITION:
			return { ...state, DynamicFormulaCurrentPosition: action.payload };
		case DYNAMIC_VARIABLE_QRCODE_PROPERTIES:
			return { ...state, DynamicVariableQRCode: action.payload };
		case EMOJI_GROUPS:
			return { ...state, Emojis: action.payload };
		case EMOJIS:
			return { ...state, Emojis: action.payload };
		default:
			return state;
	}
};

export default emailBuilder;
