import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import WelcomeUser from 'views/WelcomeUser';
import Tooltip from 'storybook-mui/build/components/Tooltip';
import { ReloadLayout, ToggleCollapse } from 'reducers/Layout/LayoutActions';

import { useHistory } from 'react-router';
import { getOrganisationIdFirebase, getUser } from 'api/AxiosManager';
import { GetOrganisationDetailsById } from 'firebaseApis/organization';
import Leap360Logo from 'assets/img/leap360.png';
import IconButtonComponent from 'storybook-mui/build/components/IconButton';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';

export const Header = ({
	sidebarOpen,
	setSidebarOpen,
	isUserShow = true,
}: INav) => {
	const [organizationData, setOrganizationData] = useState<any>(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const user = getUser();
	const layoutStore = useSelector((state: RootStore) => state.layoutReducer);

	const toggleCollapse = () => dispatch(ToggleCollapse());
	const onLogoClick = () => {
		if (user.organizationPath) {
			history.push('/Dashboard');
		}
	};

	const getData = async () => {
		try {
			const response = await GetOrganisationDetailsById(
				getOrganisationIdFirebase()
			);
			setOrganizationData(response);
			dispatch(ReloadLayout(false));
			return response;
		} catch (error) {
			dispatch(ReloadLayout(false));
			return error;
		}
	};

	useEffect(() => {
		if (layoutStore.Reload_Layout) {
			getData();
		}
	}, [layoutStore]);

	return (
		<>
			{layoutStore.HeaderNav && user ? (
				<header className='header flex items-center justify-between py-2 px-4 shadow-lg bg-gray-100 fixed w-full left-0 right-0 z-50'>
					<div className='flex items-center justify-between'>
						{user.organizationPath && (
							<>
								<div className='hidden md:flex'>
									<Tooltip direction='bottom' content='Menu'>
										<IconButtonComponent
											id='hamburger-menu'
											ariaLabel='hamburger-menu'
											onClick={toggleCollapse}
											size='large'
											content={<Icon icon={IconTypes.Menu} />}
										/>
									</Tooltip>
								</div>
								<div className='show md:hidden'>
									<Tooltip direction='bottom' content='Menu'>
										<IconButtonComponent
											id='hamburger-menu'
											ariaLabel='hamburger-menu'
											onClick={() => setSidebarOpen(!sidebarOpen)}
											size='large'
											content={<Icon icon={IconTypes.Menu} />}
										/>
									</Tooltip>
								</div>
							</>
						)}
						<div className='ml-0 md:ml-3 cursor-pointer ' onClick={onLogoClick}>
							<div className='flex items-center justify-center flex-col'>
								{organizationData?.Logo_Type === 'CUSTOM_LOGO' ? (
									<div className='w-28'>
										<img src={organizationData.Logo_Url} className='inline w-full h-12' />
									</div>
								) : organizationData?.Logo_Type === 'ORGANIZATION NAME' ? (
									<h1 className='text-xl font-semibold text-gray-500 w-56 truncate'>
										{organizationData?.Org_Name ?? 'LEAP360'}
									</h1>
								) : (
									<div className='w-28'>
										<img src={Leap360Logo} className='inline w-full h-12' />
									</div>
								)}
							</div>
						</div>
					</div>
					{isUserShow ? (
						<>
							<div className='flex items-center'>
								<WelcomeUser />
							</div>
						</>
					) : null}
				</header>
			) : (
				''
			)}
		</>
	);
};

export interface INav {
	sidebarOpen: any;
	setSidebarOpen: any;
	isUserShow?: boolean;
}

export default Header;
