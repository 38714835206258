import {
	collection,
	getDocs,
	limit,
	query,
	orderBy,
	startAfter,
	endAt,
	limitToLast,
	doc,
	getDoc,
	addDoc,
	doc as firebaseDoc,
	updateDoc,
	increment,
	deleteDoc,
	where,
	startAt,
} from 'firebase/firestore';
import { masterDb } from 'firebaseConfig';

import { getClientDB, getOrganisationIdFirebase } from '../api/AxiosManager';

const updateCreativesCount = async (val) => {
	const taskDocRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/Counts`
	);
	try {
		await updateDoc(taskDocRef, { Email_Creatives: increment(val) });
		return {};
	} catch (err) {
		return null;
	}
};

export const deleteEmailCreative = async (id) => {
	// delete children

	const qry = query(
		collection(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/CreativeTemplates/Email/${id}/HTML`
		)
	);
	const querySnapshots = await getDocs(qry);
	querySnapshots.docs.forEach(async (document: any) => {
		await deleteDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/CreativeTemplates/Email/${id}/HTML`,
				document.id
			)
		);
	});

	const taskDocRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`,
		id
	);
	try {
		await deleteDoc(taskDocRef);
		await updateCreativesCount(-1);
		return {};
	} catch (err) {
		return null;
	}
};

export const deleteEmailCreativeByCategory = async (category, type) => {
	const taskDocRef = getClientDB()
		.collection(`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`)
		.where(type === 'main' ? 'categoryId' : 'subCategoryId', '==', category);

	try {
		let count = 0;
		taskDocRef.get().then((querySnapshot) => {
			querySnapshot.forEach((docs) => {
				count++;
				docs.ref.delete();
			});
		});

		await updateCreativesCount(-count);
		return {};
	} catch (err) {
		return null;
	}
};

export const GetEmailCreativeHTMLContent = async (id) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/CreativeTemplates/Email/${id}/HTML`
			)
		);
		const querySnapshots = await getDocs(qry);
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const updateEmailCreativeWithoutHtml = async (data, id) => {
	try {
		const creativeRef = firebaseDoc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/CreativeTemplates/Email/${id}`
		);
		await updateDoc(creativeRef, data);

		return {};
	} catch (err) {
		return null;
	}
};

export const updateEmailCreative = async (data, id) => {
	const creativeRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/CreativeTemplates/Email/${id}`
	);
	try {
		await updateDoc(creativeRef, data);
		const result = await GetEmailCreativeHTMLContent(id);
		return { id: result[0].id };
	} catch (err) {
		return null;
	}
};
export const updateHTMLContent = async (html, creativeId, htmlId) => {
	const creativeRef = firebaseDoc(
		getClientDB(),
		`/${getOrganisationIdFirebase()}/CreativeTemplates/Email/${creativeId}/HTML/${htmlId}`
	);
	try {
		await updateDoc(creativeRef, { Content: html });
		return { id: htmlId };
	} catch (err) {
		return null;
	}
};

export const saveHTMLContent = async (data) => {
	try {
		const result = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/CreativeTemplates/Email/${data.id}/HTML`
			),
			{ Content: data.html }
		);
		return result;
	} catch (err) {
		return null;
	}
};
export const saveEmailCreative = async (data) => {
	try {
		const result = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
			),
			data
		);
		await updateCreativesCount(1);
		return { id: result.id };
	} catch (err) {
		return null;
	}
};
export const GetEmailCreatives = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	categoryBy,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			let qry;
			if (categoryBy.length > 0) {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
					),
					where('categoryId', 'in', categoryBy),
					// orderBy('updatedDateTime', 'desc'),
					startAfter(lastDocument),
					limit(rowsPerPage)
				);
				const mainQuerySnapshots: any = await getDocs(qry);
				if (mainQuerySnapshots.docs.length === 0) {
					qry = await query(
						collection(
							getClientDB(),
							`${getOrganisationIdFirebase()}/CreativeTemplates/Email`
						),
						where('subCategoryId', 'in', categoryBy),
						startAfter(lastDocument),
						limit(rowsPerPage)
					);
				}
			} else {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
					),
					orderBy('updatedDateTime', 'desc'),
					startAfter(lastDocument),
					limit(rowsPerPage)
				);
			}

			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			let qry;

			if (categoryBy.length > 0) {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
					),
					where('categoryId', 'in', categoryBy),
					//	orderBy('updatedDateTime', 'desc'),
					endAt(firstDocument),
					limit(rowsPerPage)
				);
				const mainQuerySnapshots: any = await getDocs(qry);
				if (mainQuerySnapshots.docs.length === 0) {
					qry = await query(
						collection(
							getClientDB(),
							`${getOrganisationIdFirebase()}/CreativeTemplates/Email`
						),
						where('subCategoryId', 'in', categoryBy),
						//	orderBy('updatedDateTime', 'desc'),
						endAt(firstDocument),
						limit(rowsPerPage)
					);
				}
			} else {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
					),
					orderBy('updatedDateTime', 'desc'),
					endAt(firstDocument),
					limitToLast(rowsPerPage)
				);
			}
			querySnapshots = await getDocs(qry);
		} else {
			let qry;
			if (categoryBy.length > 0) {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
					),
					where('categoryId', 'in', categoryBy),
					//	orderBy('updatedDateTime', 'desc'),
					limit(rowsPerPage)
				);
				const mainQuerySnapshots: any = await getDocs(qry);
				if (mainQuerySnapshots.docs.length === 0) {
					qry = await query(
						collection(
							getClientDB(),
							`${getOrganisationIdFirebase()}/CreativeTemplates/Email`
						),
						where('subCategoryId', 'in', categoryBy),
						//	orderBy('updatedDateTime', 'desc'),
						limit(rowsPerPage)
					);
				}
			} else {
				qry = query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
					),
					orderBy('updatedDateTime', 'desc'),
					limit(rowsPerPage)
				);
			}

			querySnapshots = await getDocs(qry);
		}
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			source: 'myLibraryTemplates',
			creativeType: 'Email',
			...document.data(),
		}));

		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const GetEmailCreativesCount = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), getOrganisationIdFirebase(), 'Counts')
		);
		return docResult.data().Email_Creatives;
	} catch (error) {
		return error;
	}
};

export const GetEmailCreativeById = async (id) => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`,
				id
			)
		);
		return {
			...docResult.data(),
			id,
			source: 'myLibraryTemplates',
			creativeType: 'Email',
		};
	} catch (error) {
		return error;
	}
};

export const GetEmailCreativeByName = async (name) => {
	try {
		const response = await getDocs(
			query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
				),
				where('creativeName', '==', name)
			)
		);
		const resultSnapshots = response.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};

export const GetCRMEmailCreativeById = async (id) => {
	try {
		const docResult: any = await getDoc(
			doc(masterDb, `/master/Lookups/Leap360_MarketTemplate`, id)
		);
		return {
			...docResult.data(),
			id,
			source: 'CRMTemplates',
			creativeType: 'Email',
		};
	} catch (error) {
		return error;
	}
};

export const GetExternalEmailCreativeById = async (id) => {
	try {
		const docResult: any = await getDoc(
			doc(masterDb, `/master/Lookups/ExternalTemplates`, id)
		);
		return {
			...docResult.data(),
			id,
			source: 'externalTemplates',
			creativeType: 'Email',
		};
	} catch (error) {
		return error;
	}
};

export const GetAllExternalEmailCreativesByCategory = async (category) => {
	try {
		const qry = query(
			collection(masterDb, `/master/Lookups/ExternalTemplates`),
			where('categoryName', 'in', category)
		);
		const querySnapshots = await getDocs(qry);
		const resultArray = querySnapshots.docs.map((document: any) => ({
			source: 'externalTemplates',
			id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetAllCRMEmailCreativesByCategory = async (category) => {
	try {
		const mainQry = await query(
			collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
			where('categoryId', 'in', category)
		);
		const mainQuerySnapshots: any = await getDocs(mainQry);

		const subQry = await query(
			collection(masterDb, `master/Lookups/Leap360_MarketTemplate`),
			where('subCategoryId', 'in', category)
		);

		const subQuerySnapshots: any = await getDocs(subQry);

		const totalDocs: any = [
			...mainQuerySnapshots.docs,
			...subQuerySnapshots.docs,
		];

		const resultArray = totalDocs.map((document: any) => ({
			id: document.id,
			...document.data(),
			source: 'CRMTemplates',
		}));
		return resultArray;
	} catch (error) {
		return null;
	}
};

export const GetAllInternalEmailCreativesByCategory = async (category) => {
	try {
		const mainQry = await query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/CreativeTemplates/Email`
			),
			where('categoryId', 'in', category)
		);
		const mainQuerySnapshots: any = await getDocs(mainQry);

		if (mainQuerySnapshots.docs.length > 0) {
			return mainQuerySnapshots.docs.map((document: any) => ({
				id: document.id,
				...document.data(),
			}));
		}

		const subQry = await query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/CreativeTemplates/Email`
			),
			where('subCategoryId', 'in', category)
		);

		const subQuerySnapshots: any = await getDocs(subQry);

		return subQuerySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
			source: 'myLibraryTemplates',
		}));
	} catch (error) {
		return null;
	}
};

export const GetStep1Templates = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/editors/stepone-layout`
				),
				orderBy('title', 'asc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/editors/stepone-layout`
				),
				orderBy('title', 'asc'),
				endAt(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/editors/stepone-layout`
				),
				orderBy('title', 'asc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const GetStep1TemplatesCount = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), getOrganisationIdFirebase(), 'Counts')
		);
		return docResult.data().step1_template;
	} catch (error) {
		return error;
	}
};

export const GetStep1TemplateHTML = async (id) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/editors/stepone-layout/${id}/template_html`
			)
		);
		const querySnapshots = await getDocs(qry);
		const resultArray = querySnapshots.docs.map((document: any) =>
			document.data()
		);
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetStep1TemplateFields = async (id) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/editors/stepone-layout/${id}/formFields`
			)
		);
		const querySnapshots = await getDocs(qry);
		const resultArray = querySnapshots.docs.map((document: any) =>
			document.data()
		);

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetStep1EventTypes = async (id) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/editors/EventTypes`
			),
			where('Layout', 'array-contains', id)
		);
		const querySnapshots = await getDocs(qry);
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetStep1LayoutData = async (arr) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/editors/stepone-layout-data`
			)
		);
		const querySnapshots = await getDocs(qry);

		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		const res = resultArray.filter((element) =>
			arr.find((o) => o === element.id)
		);

		return res;
	} catch (error) {
		return error;
	}
};

export const GetStep1LayoutDataByID = async (id) => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/editors/stepone-layout-data`,
				id
			)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const emailCreativeSearch = async (param) => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/CreativeTemplates/Email`
			),
			orderBy('creativeName'),
			startAt(param)
			//	endAt(`${param}\uf8ff`)
		);
		const querySnapshots = await getDocs(qry);
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetLeap360EmailCreatives = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	categoryBy,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			let qry;
			if (categoryBy.length > 0) {
				qry = query(
					collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
					where('categoryId', 'in', categoryBy),
					// orderBy('updatedDateTime', 'desc'),
					startAfter(lastDocument),
					limit(rowsPerPage)
				);
				const mainQuerySnapshots: any = await getDocs(qry);
				if (mainQuerySnapshots.docs.length === 0) {
					qry = await query(
						collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
						where('subCategoryId', 'in', categoryBy),
						startAfter(lastDocument),
						limit(rowsPerPage)
					);
				}
			} else {
				qry = query(
					collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
					orderBy('updatedDateTime', 'desc'),
					startAfter(lastDocument),
					limit(rowsPerPage)
				);
			}

			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			let qry;

			if (categoryBy.length > 0) {
				qry = query(
					collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
					where('categoryId', 'in', categoryBy),
					//	orderBy('updatedDateTime', 'desc'),
					endAt(firstDocument),
					limit(rowsPerPage)
				);
				const mainQuerySnapshots: any = await getDocs(qry);
				if (mainQuerySnapshots.docs.length === 0) {
					qry = await query(
						collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
						where('subCategoryId', 'in', categoryBy),
						//	orderBy('updatedDateTime', 'desc'),
						endAt(firstDocument),
						limit(rowsPerPage)
					);
				}
			} else {
				qry = query(
					collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
					orderBy('updatedDateTime', 'desc'),
					endAt(firstDocument),
					limitToLast(rowsPerPage)
				);
			}
			querySnapshots = await getDocs(qry);
		} else {
			let qry;
			if (categoryBy.length > 0) {
				qry = query(
					collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
					where('categoryId', 'in', categoryBy),
					//	orderBy('updatedDateTime', 'desc'),
					limit(rowsPerPage)
				);
				const mainQuerySnapshots: any = await getDocs(qry);
				if (mainQuerySnapshots.docs.length === 0) {
					qry = await query(
						collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
						where('subCategoryId', 'in', categoryBy),
						//	orderBy('updatedDateTime', 'desc'),
						limit(rowsPerPage)
					);
				}
			} else {
				qry = query(
					collection(masterDb, `/master/Lookups/Leap360_MarketTemplate`),
					orderBy('updatedDateTime', 'desc'),
					limit(rowsPerPage)
				);
			}

			querySnapshots = await getDocs(qry);
		}
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			source: 'CRMTemplates',
			creativeType: 'Email',
			...document.data(),
		}));

		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};

export const GetLeap360EmailCreativeHTMLContent = async (id) => {
	try {
		const qry = query(
			collection(masterDb, `/master/Lookups/Leap360_MarketTemplate/${id}/HTML`)
		);
		const querySnapshots = await getDocs(qry);
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const GetExternalEmailCreatives = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	categoryBy,
}) => {
	try {
		let querySnapshots: any;
		if (type === 'next') {
			let qry;
			if (categoryBy.length > 0) {
				qry = query(
					collection(masterDb, `/master/Lookups/ExternalTemplates`),
					where('categoryName', 'in', categoryBy),
					// orderBy('updatedDateTime', 'desc'),
					startAfter(lastDocument),
					limit(rowsPerPage)
				);
			} else {
				qry = query(
					collection(masterDb, `/master/Lookups/ExternalTemplates`),
					orderBy('updatedDateTime', 'desc'),
					startAfter(lastDocument),
					limit(rowsPerPage)
				);
			}

			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			let qry;

			if (categoryBy.length > 0) {
				qry = query(
					collection(masterDb, `/master/Lookups/ExternalTemplates`),
					where('categoryName', 'in', categoryBy),
					//	orderBy('updatedDateTime', 'desc'),
					endAt(firstDocument),
					limit(rowsPerPage)
				);
			} else {
				qry = query(
					collection(masterDb, `/master/Lookups/ExternalTemplates`),
					orderBy('updatedDateTime', 'desc'),
					endAt(firstDocument),
					limitToLast(rowsPerPage)
				);
			}
			querySnapshots = await getDocs(qry);
		} else {
			let qry;
			if (categoryBy.length > 0) {
				qry = query(
					collection(masterDb, `/master/Lookups/ExternalTemplates`),
					where('categoryName', 'in', categoryBy),
					//	orderBy('updatedDateTime', 'desc'),
					limit(rowsPerPage)
				);
			} else {
				qry = query(
					collection(masterDb, `/master/Lookups/ExternalTemplates`),
					orderBy('updatedDateTime', 'desc'),
					limit(rowsPerPage)
				);
			}

			querySnapshots = await getDocs(qry);
		}
		const resultArray = querySnapshots.docs.map((document: any) => ({
			id: document.id,
			source: 'externalTemplates',
			creativeType: 'Email',
			...document.data(),
		}));

		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
		};
	} catch (error) {
		return error;
	}
};
