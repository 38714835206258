import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'storybook-mui/build/components/Button';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';

const WelcomeContent = ({ gotToNextScreen }: IWelcomProps) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(increaseProgressVal(10));
	}, []);
	return (
		<>
			<div className=''>
				<div className='flex flex-col justify-center items-center pt-8 lg:pt-5'>
					<h2 className='text-2xl lg:text-5xl uppercase font-bold text-info-600 pb-2'>
						Nice to meet you.
					</h2>
					<p className='text-lg lg:text-3xl text-gray-darkest'>
						Stick with us for a few quick steps and{' '}
					</p>
					<p className='text-lg lg:text-3xl text-gray-darkest'>
						We'll get you set up for a free trail.
					</p>
				</div>
				<div className='flex items-center relative stepper-ct mt-8 lg:mt-28'>
					<div className='long-bar bg-info-600 w-full absolute z-0 hidden lg:block' />
					<div className='box-ct flex flex-col lg:flex-row items-center lg:justify-center w-full z-10 gap-4 lg:gap-14'>
						<div className='box-item w-full md:w-96 lg:w-72 xl:w-96 first-box bg-white rounded-xl relative py-4 lg:py-0'>
							<div className='trianlge'>
								<span className='text-InTrainagle'>2</span>
							</div>
							<div className='box-content flex flex-col h-full px-3 lg:pl-6 lg:pt-4  justify-center'>
								<h3 className='text-xl lg:text-2xl xl:text-3xl text-gray-darkest mb-2 lg:mb-4 uppercase font-semibold lg:font-thin text-center lg:text-left'>
									Account creation
								</h3>
								<p className='text-base text-gray-darkest lg:font-semibold text-center lg:text-left '>
									Just the basics. Just click the ‘continue’ button and we’ll get
									started.
								</p>
							</div>
						</div>
						<div className='box-item w-full md:w-96 lg:w-72 xl:w-96 second-box bg-white rounded-xl relative py-4 lg:py-0'>
							<div className='trianlge'>
								<span className='text-InTrainagle'>3</span>
							</div>
							<div className='box-content flex flex-col h-full px-3 lg:pl-6 lg:pt-4  justify-center'>
								<h3 className='text-xl lg:text-2xl xl:text-3xl text-gray-darkest mb-2 lg:mb-4 uppercase font-semibold lg:font-thin text-center lg:text-left'>
									Set up first org
								</h3>
								<p className='text-base text-gray-darkest lg:font-semibold text-center lg:text-left '>
									Information about your business to help us configure Leap360 for you.
								</p>
							</div>
						</div>
						<div className='box-item w-full md:w-96 lg:w-72 xl:w-96 third-box bg-white rounded-xl py-4 lg:py-0'>
							<div className='box-content flex flex-col h-full px-3 lg:pl-6 lg:pt-4  justify-center'>
								<h3 className='text-xl lg:text-2xl xl:text-3xl text-gray-darkest mb-2 lg:mb-4 uppercase font-semibold lg:font-thin text-center lg:text-left'>
									welcome tour
								</h3>
								<p className='text-base text-gray-darkest lg:font-semibold text-center lg:text-left '>
									An optional guided tour of our most popular features.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='bottom-section my-5 lg:my-24 flex flex-col items-center w-full lg:w-2/4 mx-auto'>
					<p className='lg:text-xl text-gray-darkest mb-4 lg:mb-8'>
						Your one month free trial gives you full access to Leap360, a great way to
						see what Leap360 can do for you. Don’t worry about hidden fees. You’ll be
						notified if you are about to exceed your free trial usages.
					</p>
					<Button
						onClick={(e) => gotToNextScreen(e, 1)}
						type='button'
						title='CONTINUE'
						size='large'
					/>
				</div>
			</div>
		</>
	);
};
export interface IWelcomProps {
	gotToNextScreen?: any;
}

export default WelcomeContent;
