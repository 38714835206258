import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Toast, { ToastTypes } from 'components/ToastNotify';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import {
	GetOrganisationInfoById,
	GetOrganisationOfUser,
} from 'firebaseApis/authentication';

import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { getUser } from 'api/AxiosManager';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';

function ChooseOrg() {
	const [orgData, setOrgData] = useState<[]>([]);
	const [readyToRender, setReadyToRender] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const selectOrganisation = async (id: any) => {
		try {
			dispatch(showLoading());
			const response = await GetOrganisationInfoById(id);
			dispatch(hideLoading());
			if (response.organizationName && response.organisationPathFirebase) {
				history.push('/Marketing/SingleChannelCampaigns'); // Temporary
				Toast({
					title: `Welcome to ${response.organizationName}`,
					type: ToastTypes.SUCCESS,
				});
				dispatch(hideLoading);
			} else {
				throw new Error('Something went wrong');
			}
		} catch (error) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			dispatch(hideLoading());
		}
	};
	const getData = async () => {
		try {
			const user = getUser();
			dispatch(showLoading());
			const resultData = await GetOrganisationOfUser(user.userId);
			dispatch(hideLoading());
			if (resultData.length === 1) {
				selectOrganisation(resultData[0].Organization_Id);
				setReadyToRender(true);
			} else {
				setOrgData(resultData);
				setReadyToRender(true);
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};
	useEffect(() => {
		getData();
		dispatch(increaseProgressVal(10));
	}, []);
	return (
		<div className='flex flex-col'>
			<div className='flex flex-col gap-8 mt-10 justify-center items-center'>
				<p
					className='text-lg uppercase font-semibold text-primary-500 hover:underline cursor-pointer'
					onClick={() => history.push('/createorg')}
				>
					Create a new organization
				</p>
				{orgData?.length !== 0 && readyToRender && (
					<>
						<div className='w-full lg:w-1/2'>
							<Divider>
								<Chip label='Select one from below' color='info' variant='outlined' />
							</Divider>
						</div>

						<div className='flex flex-col'>
							<div className='flex justify-center'>
								<div className='grid justify-center md:grid-cols-2 grid-cols-1 gap-6'>
									{orgData?.map((org: any, index) => (
										<div
											key={index}
											className='px-6 py-2 rounded-md border-gray-300 hover:border-primary-600 border cursor-pointer w-86 bg-white hover:shadow-inner'
											onClick={() => selectOrganisation(org.Organization_Id)}
										>
											<h1 className='text-lg font-bold text-gray-500 text-center hover:text-primary-600'>
												{org.Org_Name}
											</h1>
										</div>
									))}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default ChooseOrg;
