import {
	SET_ORGANIZATION_DATA,
} from './OrganizationTypes';


const initialState = {
};

const OrganizationReducer = (
	state: any = initialState,
	action: any
) => {
	switch (action.type) {
		case SET_ORGANIZATION_DATA:
			return {
				...state,
				organizations: action.payload,
			};
		default:
			return state;
	}
};

export default OrganizationReducer;
