import React, { useState, useEffect } from 'react';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Divider, Slide } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AddExistingUserToOrganisation } from 'firebaseApis/users';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import InputTextField from 'storybook-mui/build/components/TextField';
import AutocompleteField from 'storybook-mui/build/components/Autocomplete';
import CheckboxField from 'storybook-mui/build/components/Checkbox';
import Header from 'layouts/components/Header';
import SelectField from 'storybook-mui/build/components/Select';
import Modal from 'storybook-mui/build/components/Modal';
import { BasicRegister, GetAllCountries } from 'firebaseApis/authentication';
import {
	SetAuthenticationType,
	SetUserInfo,
} from 'reducers/Authentication/AuthActions';
import { showLoading, hideLoading } from 'reducers/Alerts/AlertActions';
import showProgressImg from 'assets/img/progress-show.svg';
import WelcomeLinearProgressBar from 'components/Welcome-comp/Welcome-progress-bar';
import WelcomeContent from 'layouts/components/Welcome-content';
import { RootStore } from 'reducers';
import { signupSchema } from './validations/signupValidation';
import SocialLogins from './SocialLogins';
import { INewUser, IUserDataInLocalStorage } from './onboardInterfaces';

export const RegisterForm = ({
	fromAdmin,
	setOpenAddUserModelFromAdmin,
	setUserAddedFromAdmin,
}: IRegisterForm) => {
	const [viewType, setViewType] = useState<'welcome-screen' | 'register-form'>(
		'welcome-screen'
	);
	const dispatch = useDispatch();
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
	const [showProgresslabel, setShowProgressLabel] = useState(true);
	const { progressVal } = useSelector(
		(state: RootStore) => state.welcomeProgressBarReducer
	);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [passwordVisibility, setPasswordVisibility] = useState(true);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(true);
	const [validationOnChange, setValidateOnChange] = useState(false);
	const history = useHistory();
	const [showPasswordReqModal, setShowPasswordReqModal] = useState(false);
	const [showTermsPolicyModal, setShowTermsPolicyModal] = useState(false);
	const [countryCodes, setCountryCodes] = useState([]);

	const registerUser = async (values: INewUser) => {
		try {
			dispatch(showLoading());
			const result = await BasicRegister(values);
			if (result.success) {
				const dataForLocalStorage: IUserDataInLocalStorage = {
					fName: values.FirstName,
					lName: values.LastName,
					email: values.Email,
					userId: result.data.id,
					emailVerified: false,
					mobileType: values.select_Phone_Type,
					countryCode: values.phone_countryCode,
					phoneNumber: values.phoneNumber,
					landline_ext: values.landline_ext,
					email_optIn: values.email_optIn,
					sms_optIn: values.sms_optIn,
					voice_optIn: values.voice_optIn,
					userRegistrationType: 'basic',
				};
				if (!fromAdmin) {
					Toast({ title: 'Registration Successfull', type: ToastTypes.SUCCESS });
					dispatch(hideLoading());
					dispatch(SetUserInfo(values));
					dispatch(SetAuthenticationType('REGISTER'));
				}
				if (fromAdmin) {
					const result2 = await AddExistingUserToOrganisation(
						dataForLocalStorage.userId,
						'',
						'',
						null
					);
					if (result2.success) {
						setUserAddedFromAdmin(true);
						setOpenAddUserModelFromAdmin(false);
					} else {
						Toast({ title: result2.message, type: ToastTypes.ERROR });
					}
				} else {
					dispatch(SetUserInfo(dataForLocalStorage));
					history.push('/verifyemail');
				}
			} else {
				dispatch(hideLoading());
				Toast({ title: result.message, type: ToastTypes.ERROR });
			}
		} catch (error) {
			dispatch(hideLoading());
			Toast({ title: 'Something Went Wrong', type: ToastTypes.ERROR });
		}
	};

	const registerForm = useFormik({
		validationSchema: signupSchema,
		validateOnChange: validationOnChange,
		initialValues: {
			FirstName: '',
			LastName: '',
			Email: '',
			Name: '',
			Password: '',
			ConfPassword: '',
			email_optIn: true,
			sms_optIn: true,
			voice_optIn: true,
			select_Phone_Type: 'Mobile',
			landline_ext: '',
			phone_countryCode: '',
			phoneNumber: '',
		},
		onSubmit: (values, formikProps) => {
			formikProps.setSubmitting(false);
			if (confirmPassword === values.Password)
				registerUser({
					...registerForm.values,
					UID: '',
					userRegistrationType: 'basic',
					ProfilePicUrl: '',
				});
		},
	});

	const getData = () => {
		GetAllCountries().then((res: any) => {
			const tempCountryNames: any = [];
			const tempCountryCodes: any = [];
			const response: any = res;
			response.forEach((obj: any) => {
				tempCountryCodes.push({
					value: `${obj.phone_code}`,
					label: `${obj.phone_code}`,
					renderLabel: (
						<div className='flex items-center'>
							<span className='h-8 -mb-2'>
								<img src={obj.flag_svg_url} className='h-6 w-6 mx-2' />
							</span>
							<span className='ml-1'>{`${obj.phone_code}`} </span>
						</div>
					),
				});
			});

			setCountryCodes(tempCountryCodes);
			response.forEach((obj: any) => {
				tempCountryNames.push({
					value: `${obj.country_id}`,
					label: obj.country_name,
					id: obj.country_id,
					states: obj.states,
					renderLabel: (
						<div className='flex items-center'>
							<span className='h-8 -mb-2'>
								<img src={obj.flag_svg_url} className='h-6 w-6 mx-2' />
							</span>
							<span className='ml-1'>{obj.country_name} </span>
						</div>
					),
				});
			});
		});
	};
	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			{!fromAdmin && (
				<div className='flex flex-col h-full'>
					<Header
						sidebarOpen={sidebarOpen}
						setSidebarOpen={setSidebarOpen}
						isUserShow={false}
					/>
					<WelcomeLinearProgressBar
						color='primary'
						delay={300}
						value={progressVal}
						showBackDrop={false}
						message=''
					/>
					{showProgresslabel && (
						<div className='show-progress-img-ct hidden lg:block'>
							<img src={showProgressImg} alt='show-progress' />
							<label className='text-gray-darkest progress-text'>Your progress</label>
						</div>
					)}
				</div>
			)}
			<div className='bg-gray-lightest h-full w-full pt-20 main-content px-4 lg:px-0'>
				{viewType === 'welcome-screen' && (
					<WelcomeContent
						gotToNextScreen={() => {
							setViewType('register-form');
							setShowProgressLabel(false);
						}}
					/>
				)}

				{viewType === 'register-form' && (
					<Slide direction='up' in mountOnEnter unmountOnExit>
						<div className='md:w-3/4 lg:w-1/2 mx-auto flex flex-col pt-5 mb-10'>
							<h2 className='text-center lg:text-left text-xl md:text-2xl lg:text-4xl uppercase font-bold text-info-600 pb-12'>
								Let’s create your login
							</h2>
							<div className='w-full shadow-custom bg-white rounded-md'>
								<div>
									{!fromAdmin && (
										<>
											<SocialLogins />
											<Divider sx={{ my: 4 }}>OR</Divider>
										</>
									)}

									{!showConfirmPassword && (
										<form
											className='flex gap-4 flex-col lg:flex-row mt-5'
											onSubmit={(e) => {
												e.preventDefault();
												registerForm.handleSubmit();
											}}
										>
											<div className='flex flex-col w-full space-y-4'>
												<div className='flex flex-col lg:flex-row lg:space-x-5 lg:space-y-0 space-y-4 px-4'>
													<InputTextField
														name='FirstName'
														fieldId='firstName'
														labelName='First Name'
														value={registerForm.values.FirstName}
														errorMessage='Required'
														valid={registerForm.errors.FirstName === undefined}
														onChange={registerForm.handleChange}
														autoComplete='given-name'
													/>
													<InputTextField
														name='LastName'
														fieldId='lastName'
														labelName='Last Name'
														value={registerForm.values.LastName}
														errorMessage='Required'
														valid={registerForm.errors.LastName === undefined}
														onChange={registerForm.handleChange}
														autoComplete='family-name'
													/>
												</div>
												<div className='grid grid-cols-1 lg:grid-cols-3 px-4 gap-4'>
													<div className='lg:col-span-2 flex flex-col gap-4'>
														<InputTextField
															name='Email'
															fieldId='email'
															labelName="What's a good email ?"
															value={registerForm.values.Email}
															valid={registerForm.errors.Email === undefined}
															errorMessage={registerForm.errors.Email}
															onChange={registerForm.handleChange}
															autoComplete='email'
														/>
														<InputTextField
															autoComplete='new-password'
															name='Password'
															type={passwordVisibility ? 'password' : 'text'}
															fieldId='password'
															labelName='Password'
															value={registerForm.values.Password}
															errorMessage={registerForm.errors.Password}
															valid={registerForm.errors.Password === undefined}
															onChange={registerForm.handleChange}
															adornmentMessage={
																<IconButton
																	aria-label='toggle password visibility'
																	onClick={() => setPasswordVisibility(!passwordVisibility)}
																	onMouseDown={(event) => event.preventDefault()}
																>
																	{passwordVisibility ? <VisibilityOff /> : <Visibility />}
																</IconButton>
															}
														/>
														<div>
															<InputTextField
																name='ConfirmPassword'
																type={confirmPasswordVisibility ? 'password' : 'text'}
																autoComplete='new-password'
																fieldId='ConfirmPassword'
																labelName='Confirm Password'
																valid={
																	!confirmPassword ||
																	confirmPassword === registerForm.values.Password
																}
																errorMessage='Password does not match'
																value={confirmPassword}
																onChange={(e: any) => setConfirmPassword(e.target.value)}
																adornmentMessage={
																	<IconButton
																		aria-label='toggle password visibility'
																		onClick={() =>
																			setConfirmPasswordVisibility(!confirmPasswordVisibility)
																		}
																		onMouseDown={(event) => event.preventDefault()}
																	>
																		{confirmPasswordVisibility ? (
																			<VisibilityOff />
																		) : (
																			<Visibility />
																		)}
																	</IconButton>
																}
															/>
															<span
																className='text-xs sub-smallLabel capitalize'
																onClick={() => setShowPasswordReqModal(true)}
															>
																password requirements ?
															</span>
														</div>
														<div className='grid grid-cols-2 gap-4'>
															<SelectField
																placeholder='PhoneType'
																value={registerForm.values.select_Phone_Type}
																options={[
																	{ label: 'Mobile', value: 'Mobile' },
																	{ label: 'Landline', value: 'Landline' },
																]}
																name='select_Phone_Type'
																fieldId='selectPhoneType'
																displayField='label'
																valueField='value'
																onChange={registerForm.handleChange}
																errorMessage='Required'
															/>

															<AutocompleteField
																{...(registerForm.values.phone_countryCode && {
																	value: countryCodes.find(
																		(item: any) =>
																			item.value === registerForm.values.phone_countryCode
																	),
																})}
																onChange={(event: any, option: any) => {
																	registerForm.setFieldValue('phone_countryCode', option.value);
																}}
																options={countryCodes}
																optionsType='render'
																fieldId='countryCode'
																placeholder='Country code'
															/>
														</div>
														<div className='grid grid-cols-3 gap-4'>
															<div
																className={`${
																	registerForm.values.select_Phone_Type === 'Landline'
																		? 'col-span-2'
																		: 'col-span-3'
																}`}
															>
																<InputTextField
																	name='phoneNumber'
																	fieldId='phoneNumber'
																	onChange={registerForm.handleChange}
																	value={registerForm.values.phoneNumber}
																	errorMessage={registerForm.errors.phoneNumber}
																	valid={registerForm.errors.phoneNumber === undefined}
																	labelName='Phone Number'
																	type='number'
																/>
															</div>

															{registerForm.values.select_Phone_Type === 'Landline' && (
																<InputTextField
																	fieldId='landline_ext'
																	name='landline_ext'
																	value={registerForm.values.landline_ext}
																	onChange={registerForm.handleChange}
																	labelName='ext.'
																	type='number'
																	valid={registerForm.errors.landline_ext === undefined}
																	errorMessage={registerForm.errors.landline_ext}
																/>
															)}
														</div>
													</div>
													<div className='w-full p-5 pl-6 pb-0 relative hidden lg:block'>
														<span className='terms-privacy-text font-normal text-xs leading-tight'>
															By clicking "Start", you agree to the
															<span
																className='terms-privacy-sub-text hover:underline text-gray-darkest text-bold'
																onClick={() => setShowTermsPolicyModal(true)}
															>
																{' '}
																Terms & Conditions, License Agreement,
															</span>{' '}
															and acknowledge receipt of our
															<span className='terms-privacy-sub-text'>Privacy Notice</span>.
														</span>
														<div className='submit-btn-ct'>
															<Button
																onClick={() => {
																	setValidateOnChange(true);
																}}
																title={<h3 className='text-xl'>start</h3>}
																type='submit'
																startIcon={
																	<Icon icon={IconTypes.Transfer} className='text-white' />
																}
															/>
														</div>
													</div>
												</div>
												<div className='form-bottom-text'>
													<h4 className='pl-4 uppercase text-base font-bold text-primary-500'>
														Opt in for communications via
													</h4>
													<div className='flex flex-col bg-info-600 text-gray-lightest pl-4 pb-2 lg:rounded-md'>
														<div className='flex'>
															<div>
																<CheckboxField
																	name='email_optIn'
																	checked={registerForm.values.email_optIn}
																	onChange={registerForm.handleChange}
																	labelName={
																		<div>
																			<span className='font-light'>Email</span>
																		</div>
																	}
																	fieldId='email_optIn'
																/>
															</div>
															<div>
																<CheckboxField
																	name='sms_optIn'
																	checked={registerForm.values.sms_optIn}
																	onChange={registerForm.handleChange}
																	labelName={
																		<div>
																			<span className='font-light'>Text</span>
																		</div>
																	}
																	fieldId='sms_optIn'
																/>
															</div>
															<div>
																<CheckboxField
																	name='voice_optIn'
																	checked={registerForm.values.voice_optIn}
																	onChange={registerForm.handleChange}
																	labelName={
																		<div>
																			<span className='font-light'>Voice</span>
																		</div>
																	}
																	fieldId='voice_optIn'
																/>
															</div>
														</div>
														<p className='text-xs uppercase font-bold'>
															for product updates, marketing material, and account updates.
														</p>
													</div>
												</div>
												<div className='w-full py-4 px-4 pt-0 gap-2 relative flex flex-col lg:hidden'>
													<span className='terms-privacy-text font-normal text-xs leading-tight'>
														By clicking "Start", you agree to the
														<span
															className='terms-privacy-sub-text hover:underline text-gray-darkest text-bold'
															onClick={() => setShowTermsPolicyModal(true)}
														>
															{' '}
															Terms & Conditions, License Agreement,
														</span>{' '}
														and acknowledge receipt of our
														<span className='terms-privacy-sub-text'> Privacy Notice</span>.
													</span>
													<Button
														onClick={() => {
															setValidateOnChange(true);
														}}
														title={<h3 className='text-xl'>start</h3>}
														type='submit'
														startIcon={
															<Icon icon={IconTypes.Transfer} className='text-white' />
														}
													/>
												</div>
											</div>
										</form>
									)}
									{showConfirmPassword && (
										<div className='flex mb-3 gap-4 flex-col'>
											<div className='flex flex-col w-full space-y-4'>
												<div className='flex flex-col'>
													<h1 className='mb-4 text-primary-500 text-xl'>
														{registerForm.values.Email}
													</h1>
												</div>
											</div>
											<div className='flex justify-between'>
												<Button
													onClick={() => setShowConfirmPassword(false)}
													title='Edit'
													color='info'
													variant='outlined'
													startIcon={<Icon icon={IconTypes.ArrowBackIos} />}
												/>
												<Button
													onClick={() =>
														confirmPassword === registerForm.values.Password &&
														registerUser({
															...registerForm.values,
															UID: '',
															userRegistrationType: 'google',
															ProfilePicUrl: '',
														})
													}
													title='next'
													type='submit'
													endIcon={<Icon icon={IconTypes.ArrowForwardIos} />}
													disabled={
														!confirmPassword ||
														confirmPassword !== registerForm.values.Password
													}
												/>
											</div>
										</div>
									)}
									<Modal
										size='xs'
										modalId='passwordRequrmnt-modal'
										open={showPasswordReqModal}
										setOpen={() => setShowPasswordReqModal(!showPasswordReqModal)}
										closeIcon
										title=''
										content={
											<div className='flex flex-col gap-4 pt-4'>
												<h1 className='font-semibold text-lg text-gray-600'>
													Password requirments content coming soon...
												</h1>
												<div className='flex justify-end'>
													<Button
														title='OK'
														size='small'
														onClick={() => setShowPasswordReqModal(false)}
													/>
												</div>
											</div>
										}
									/>
									<Modal
										size='xs'
										modalId='termsAndPolicy-modal'
										open={showTermsPolicyModal}
										setOpen={() => setShowTermsPolicyModal(!showTermsPolicyModal)}
										closeIcon
										title=''
										content={
											<div className='flex flex-col gap-4 pt-4'>
												<h1 className='font-semibold text-lg text-gray-600'>
													Terms & Policy content coming soon...
												</h1>
												<div className='flex justify-end'>
													<Button
														title='OK'
														size='small'
														onClick={() => setShowTermsPolicyModal(false)}
													/>
												</div>
											</div>
										}
									/>
								</div>
							</div>
							<h4 className='text-2xl xl:text-3xl text-gray-600 mt-4'>
								After this you’ll set up your organization.
							</h4>
						</div>
					</Slide>
				)}
			</div>
		</>
	);
};

export interface IRegisterForm {
	fromAdmin?: boolean;
	setOpenAddUserModelFromAdmin?: any;
	setUserAddedFromAdmin?: any;
}
export default RegisterForm;
