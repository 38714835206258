import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
	getSiteConfig,
	setEmailBuilderAssetsFromStorage,
} from 'firebaseApis/authentication';
import { Spinner } from 'components/Spinner';
import { IdleTimerProvider } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { clear, getUser } from 'api/AxiosManager';
import { showLoading } from 'reducers/Alerts/AlertActions';

export interface IScroll {
	children: React.ReactNode;
}

const ScrollToTop = ({ children }: IScroll) => {
	const location = useLocation();
	const [siteConfig, setSiteConfig] = useState<any>(null);
	const fetchData = async () => {
		const result = await getSiteConfig(window.location.origin);
		setSiteConfig(result);
		localStorage.setItem('siteConfig', JSON.stringify(result));
	};

	const user = getUser();
	const history = useHistory();
	const dispatch = useDispatch();
	const onIdle = () => {
		// Close Modal Prompt
		// Do some idle action like log out your user
		dispatch(showLoading());
		clear();
		window.onbeforeunload = null;
		history.push('/');
		window.location.reload();
	};

	const getEmailAssetStorageBucket = async () => {
		const result = await setEmailBuilderAssetsFromStorage(window.location.origin);
		if (result) {
			localStorage.setItem(
				'emailBuilderAssetsBucket',
				JSON.stringify(result.Constant_Value)
			);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		const siteConfigObj = localStorage.getItem('siteConfig');
		if (!JSON.parse(siteConfigObj)) {
			fetchData();
		} else {
			setSiteConfig(JSON.parse(siteConfigObj));
		}

		const emailBuilderBucket = localStorage.getItem('emailBuilderAssetsBucket');

		if (!JSON.parse(emailBuilderBucket) && user?.organisationPathFirebase) {
			getEmailAssetStorageBucket();
		}
	}, [location]);

	if (siteConfig) {
		return (
			<>
				{children}
				{Object.keys(user ?? null).length !== 0 && user?.idleTimeOut && (
					<IdleTimerProvider timeout={1000 * user.idleTimeOut} onIdle={onIdle} />
				)}
			</>
		);
	}
	return <Spinner />;
};

export default ScrollToTop;
