export const SET_BOOKING_NAME = 'SET_BOOKING_NAME';
export const BOOKINGS_UPDATE_VIEW = 'BOOKINGS_UPDATE_VIEW';
export const SET_BOOKING_DESCRIPTION = 'SET_BOOKING_DESCRIPTION';
export const SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS';
export const SET_CUSTOMERINFORMATION = 'SET_CUSTOMERINFORMATION';
export const SET_LOCATION_ARRAY = 'SET_LOCATION_ARRAY';
export const SET_CHECKED_PACKAGES = 'SET_CHECKED_PACKAGES';
export const RESET_BOOKINGS = 'RESET_BOOKINGS';
export const SET_FOOD_ADD_ON_PRODUCTS='FOOD_ADD_ON_PRODUCTS'
export const SET_DECORATIVE_ADD_ON_PRODUCTS='DECORATIVE_ADD_ON_PRODUCTS'
